import {
  getSysDisplayField,
  getSysDisplayFieldAndStyleField,
  getSysField,
  NODE_HAS_CHILDREN_FIELD
} from 'utilsOld/sysFormUtils';
import {fields, system} from 'services/objects';
import FormField from 'utilsOld/systemObjects/FormField';
import {isDefined} from 'services/SecondaryMethods/typeUtils';
import {createGuid} from 'utilsOld/createGuid';

export const getFieldByName = (viewSource, fieldName) => viewSource.find(item => item[fields.Name] === fieldName);

/**
 * @param viewSource
 * @param objectName
 * @return {SysFormFields | undefined}
 */
export const getFieldByObject = (viewSource, objectName) => {
  return viewSource.find(field => {
    const fld = new FormField(field);
    return fld.objectFieldIDName === objectName;
  });
};

/**
 * Базовый метод конвертирует данные строки с бека в табличную
 * @param {SysFormFields} field
 * @param {Object} row
 * @return {Object}
 */
function prepareColumns(field, row) {
  const formField = new FormField(field);
  const name = formField.name;
  const obj = {};

  const valOrNull = val => (isDefined(val) ? val : null);

  if (formField.isLookup()) {
    obj[name] = valOrNull(row[formField.objectFieldIDName]);
    obj[getSysDisplayField(name)] = valOrNull(row[formField.lookupDisplayField]);
    if (formField.lookupTagStylingMode) {
      obj[getSysDisplayFieldAndStyleField(name, formField.LookupTagStylingModeFieldName)] = valOrNull(row[formField.lookupTagStylingMode]);
    }
    if (formField.lookupTagColorScheme) {
      obj[getSysDisplayFieldAndStyleField(name, formField.LookupTagColorSchemeFieldName)] = valOrNull(row[formField.lookupTagColorScheme]);
    }
  } else {
    let val = row[formField.objectFieldIDName];
    obj[name] = valOrNull(val);

    if (formField.getFieldType() === system.DX_CONTROLS.dxDateBox && val) {
      obj[name] = new Date(val);
    }
  }
  return obj;
}

/**
 * Конвертирует данные строки с бека в табличную.
 *  Добавляеть системное ключевое значение
 * @param {Object} arg
 * @param {SysFormFields[]} arg.viewSource
 * @param {Object} arg.row
 * @param {SysForm} arg.sysForm
 * @param {string[]} arg.columnNames
 * @return {Record}
 */
export function prepareSourceRow({row, viewSource, columnNames, sysForm}) {
  const keyField = sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  const parentFieldName = sysForm[fields.ParentFieldID_Name];

  let result = {
    [getSysField(keyField)]: row[keyField] || createGuid(),
  };

  if (parentFieldName) {
    result[getSysField(parentFieldName)] = row[parentFieldName];
  }

  if (NODE_HAS_CHILDREN_FIELD in row) {
    result[NODE_HAS_CHILDREN_FIELD] = row[NODE_HAS_CHILDREN_FIELD];
  }

  return columnNames.reduce((acc, name) => {
    const field = getFieldByName(viewSource, name);
    return {
      ...prepareColumns(field, row),
      ...acc,
    };
  }, result);
}

/**
 * достаем из массива value поля по переданному ключу
 * @param fieldsArr {Array}
 * @param keyName {String}
 * @return {[String | Number] | []}
 */
export const mapValueByKey = (fieldsArr, keyName) => {
  return Array.isArray(fieldsArr) ? fieldsArr.map(field => field[keyName]) : [];
};

/**
 * в дереве нужно проверить есть ли
 * - parent переданного ключа в отрисованных нодах
 * - входящий ключ в отрисованных нодах
 * - значение Parent у текущей строки равное null
 *
 * @param key {number}
 * @param rowData {Object}
 * @param parentFieldName {String}
 * @param fakeRootID {number}
 * @param dsItemsIds {[number]}
 * @returns {Boolean | string}
 */
export const checkNodeInDS = ({key, rowData, dsItemsIds, parentFieldName, fakeRootID}) => {
  //если отрисована только fakeRow
  if (!rowData || onlyFakeRowVisible(dsItemsIds, fakeRootID)) {
    return false;
  }
  //входящий ключ в отрисованных нодах
  if (dsItemsIds.includes(key)) {
    return system.TREE_REFRESH_NODE_CASE.HAS_ID;
  }
  //значение Parent у текущей строки равное null
  if (rowData[parentFieldName] === null) {
    return system.TREE_REFRESH_NODE_CASE.EMPTY_PARENT;
  }
  //parent переданного ключа в отрисованных нодах
  if (dsItemsIds.includes(rowData[parentFieldName])) {
    return system.TREE_REFRESH_NODE_CASE.HAS_PARENT;
  }
  return false;
};

/**
 * возвращает true если fakeRoot collapsed
 * @param dsItemsIds {[string | number]} id's трисованные в таблице
 * @param fakeRootID {string | number} значение fakeRowID
 * @returns {boolean}
 */
export const onlyFakeRowVisible = (dsItemsIds, fakeRootID) => {
  return dsItemsIds.every(id => id.toString() === fakeRootID.toString());
};

export const addItemsExpr = (data, parentField, keyField) => {
  return data.map(item => {
    item[NODE_HAS_CHILDREN_FIELD] = !!data.find(seekedItem => seekedItem[parentField] === item[keyField]);
    return item;
  });
};

export const manageExpandedState = (component, operation, keys, defaultLevel) => {
  component.beginUpdate();
  if (keys) {
    keys.forEach(key => {
      component[operation](key);
    });
  } else {
    component.forEachNode(({hasChildren, key, level}) => {
      const iterator = defaultLevel ? level < defaultLevel && hasChildren : hasChildren;
      if (iterator) {
        component[operation](key);
      }
    });
  }
  component.endUpdate();
};

export const ifAllDataWithZeros = data => {
  // Приводимо значення до Number, адже раніше функція errorSummary заповняла значення просто 0,
  // А тепер ми адаптуємо значення відповідно до налаштувань
  // і коректного відображення Number Scale, масок і т.д
  return Object.keys(data).some(key => Number(data[key]));

};


export const defaultResult = () => ({
  data: [],
  summary: {},
  totalCount: -1,
  pagesPredicted: 0
});
