import {createReducer} from '@reduxjs/toolkit';
import {
  activateGoToButton,
  clearExpandedState,
  clearRowsToUpdate,
  clearTableRowData,
  destroyTable,
  disableButtons,
  hideButtons, initTableState,
  resetPageSize,
  setButtons,
  setColumns,
  setDefaultPageSize,
  setDocFilter,
  setExpandedState,
  setHeaderContextMenuButtons,
  setPageSize,
  setPagesPredicted,
  setReportList,
  setSummaryValues,
  switchButtonsPressed,
  tableRowUpdateByIds,
  toggleButtonOption, toggleTotalRow,
  toolbarToDefaultState,
  updateColumnsByColumnSettingsTab,
  updateTables
} from './actions';
import {IButtonOptions, ILayoutItem} from '../SecondaryMethods/formItems/itemInterfaces';
import {isArray, isEmptyValue} from '../SecondaryMethods/typeUtils';
import {TablesItemState, TableState} from './interfaces';
import {BUTTON_TYPE} from '../interfaces/global-interfaces';
import {fields} from '../objects';
import {GridColumn} from 'components/TableCoreOld/column-interfaces';

const createTableState = (
  state: TableState,
  key: string,
  {
    formData = null,
    columns = [],
    buttons = [],
    summary = {},
    rowsToUpdate = null,
    isShowTotalRow = true,
    defaultTotalRowKind = 0,
    reportList = [],
    docPanelFilters = [],
    defaultPageSize,
    pageSize
  }: Partial<TablesItemState>
) => {
  if (!key) {
    return;
  }

  state[key] = {
    formData,
    columns,
    buttons,
    summary,
    isShowTotalRow,
    defaultTotalRowKind,
    rowsToUpdate,
    reportList,
    docPanelFilters,
    defaultPageSize,
    pageSize,
    expandedState: []
  };
};

export const tablesReducer = createReducer({} as TableState, builder => {
  builder
    .addCase(clearTableRowData, (state, action) => {
      if (!state[action.payload]) {
        return;
      }
      state[action.payload].formData = null;
    })
    .addCase(destroyTable, (state, action) => {
      delete state[action.payload];
    })
    .addCase(setColumns, (state, {payload}) => {
      if (!state[payload.formKey]) {
        createTableState(state as TableState, payload.formKey, {columns: payload.columns});
        return;
      }
      // @ts-ignore
      state[payload.formKey].columns = payload.columns;
    })
    .addCase(setReportList, (state, {payload}) => {
      if (!state[payload.formKey]) {
        createTableState(state as TableState, payload.formKey, {reportList: payload.reportList});
        return;
      }
      state[payload.formKey].reportList = payload.reportList;
    })
    .addCase(setSummaryValues, (state, {payload}) => {
      if (!state[payload.formKey]) {
        createTableState(state as TableState, payload.formKey, {summary: payload.summaryValues});
        return;
      }
      state[payload.formKey].summary = payload.summaryValues;
    })
    .addCase(clearRowsToUpdate, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }
      state[payload.formKey].rowsToUpdate = null;
    })
    .addCase(setExpandedState, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }
      state[payload.formKey].expandedState = [{operation: payload.operation}];
    })
    .addCase(clearExpandedState, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }
      state[payload.formKey].expandedState = [];
    })
    .addCase(setButtons, (state, {payload}) => {
      if (!state[payload.formKey]) {
        createTableState(state as TableState, payload.formKey, {buttons: payload.buttons});
        return;
      }
      // @ts-ignore
      state[payload.formKey].buttons = payload.buttons;
    })
    .addCase(initTableState, (state, {payload}) => {
      if (state[payload.formKey]) {
        return;
      }
      createTableState(state as TableState, payload.formKey, {
        buttons: payload.buttons,
        columns: payload.columns,
        isShowTotalRow: payload.isShowTotalRow,
        defaultTotalRowKind: payload.defaultTotalRowKind,
      });
    })
    .addCase(updateTables, (state, {payload}) => {
      payload.forEach(({formKey, ...item}) => {
        if (!state[formKey]) {
          return;
        }
        Object.entries(item).forEach(([key, value]) => {
          // @ts-ignore
          state[formKey][key] = value;
        });
      });
    })
    .addCase(toggleButtonOption, (state, {payload}) => {
      const button = state[payload.formKey].buttons.find(
        buttonItem => buttonItem.options.buttonType === payload.buttonType
      );
      if (button) {
        // @ts-ignore
        button.options[payload.option] = !button.options[payload.option];
      }
    })
    .addCase(hideButtons, (state, {payload: {formKey, buttons}}) => {
      if (!state[formKey]) {
        return;
      }
      state[formKey].buttons.forEach(el => {
        if (buttons.includes(el.options.buttonType)) {
          el.isVisible = false;
        }
      });
    })
    .addCase(switchButtonsPressed, (state, {payload: {formKey, buttons, value}}) => {
      if (!state[formKey]) {
        return;
      }
      state[formKey].buttons.forEach(el => {
        if (buttons.includes(el.options.buttonType)) {
          el.options.isPressed = value;
        }
      });
    })
    .addCase(activateGoToButton, (state, {payload: {formKey, selectedData}}) => {
      if (!state[formKey]) {
        return;
      }

      state[formKey].buttons.forEach(button => {
        if (button.options.buttonType === BUTTON_TYPE.GOTO) {
          const rules = button.options.buttonFillingRules;
          const hasValuesToGoTo =
            rules
              ?.map(rule => rule[fields.SourceFieldID_Name])
              .some(ruleSourceField => {
                return selectedData.some(
                  rowData => rowData.hasOwnProperty(ruleSourceField) && !isEmptyValue(rowData[ruleSourceField])
                );
              }) ?? true;
          button.options.disabled = !hasValuesToGoTo;
        }
      });
    })
    .addCase(setPageSize, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }
      state[payload.formKey].pageSize = payload.pageSize;
    })
    .addCase(setDefaultPageSize, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }

      state[payload.formKey].defaultPageSize = payload.defaultPageSize;
    })
    .addCase(resetPageSize, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }

      state[payload.formKey].pageSize = state[payload.formKey].defaultPageSize;
    })
    .addCase(setHeaderContextMenuButtons, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }

      const buttons = state[payload.formKey].buttons as ILayoutItem<IButtonOptions>[];
      buttons.push(...payload.buttons);
    })
    .addCase(setDocFilter, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }
      state[payload.formKey].docPanelFilters = payload.filter;
    })
    .addCase(disableButtons, (state, {payload}) => {
      if (!state[payload.formKey] || !isArray(payload.enabled) || !isArray(payload.disabled)) {
        return;
      }
      state[payload.formKey].buttons.forEach(btn => {
        if (payload.disabled.includes(btn.options.buttonType)) {
          btn.options.disabled = true;
          return;
        }
        if (payload.enabled.includes(btn.options.buttonType)) {
          btn.options.disabled = false;
        }
      });
    })
    .addCase(tableRowUpdateByIds.fulfilled, (state, {payload}) => {
      if (!payload || !state[payload.formKey]) {
        return;
      }
      state[payload.formKey].formData = payload.formData;
    })
    .addCase(toggleTotalRow.fulfilled, (state, {payload}) => {
      if (!payload || !state[payload.formKey]) {
        return;
      }
      state[payload.formKey].isShowTotalRow = payload.visible;
    })
    .addCase(setPagesPredicted, (state, {payload}) => {
      if (!state[payload.formKey]) {
        createTableState(state as TableState, payload.formKey, {pageSize: payload.pagesPredicted});
        return;
      }
      state[payload.formKey].pagesPredicted = payload.pagesPredicted;
    })
    .addCase(toolbarToDefaultState, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }

      const defButtonsById: any = {};
      payload.buttons.forEach(defButton => {
        defButtonsById[defButton.id] = defButton;
      });

      state[payload.formKey].buttons.forEach((btn, index) => {
        const defButton = defButtonsById[btn.id];
        if (!defButton) {
          return;
        }
        state[payload.formKey].buttons[index] = defButton;
      });
    })
    .addCase(updateColumnsByColumnSettingsTab, (state, {payload}) => {
      if (!state[payload.formKey]) {
        return;
      }
      const aDataSource = payload.newState;

      const mergeChanges = (columns: GridColumn[]) => {
        for (let i = 0; i < columns.length; i++) {
          const columnItem = columns[i];
          const dsIndex = aDataSource!.findIndex(columnItemDS => columnItemDS.caption === columnItem.caption);

          if (dsIndex === -1) continue;

          const wasJustHidden = !aDataSource[dsIndex].visible;
          columns[i].visibleIndex = dsIndex + 1;
          columns[i].visible = aDataSource[dsIndex].visible;
          columns[i].isVisibleOnEditDockPanel = aDataSource[dsIndex].isVisibleOnEditDockPanel;

          if (columns[i].userData?.isDynamicColumn) {
            if (wasJustHidden) {
              columns[i].userData.wasHiddenByUser = true;
            } else {
              delete columns[i].userData.wasHiddenByUser;
            }
          }

          if (columnItem.columns?.length) mergeChanges(columnItem.columns);
        }
      };

      // @ts-ignore
      mergeChanges(state[payload.formKey].columns || []);
    });
});
