import {fields, system} from '../objects';
import {getUIVersionLS} from '../../components/useUIVersion';

/**
 * в случае если servicePrefix одно из
 * @link {system.SERVICE_PREFIX} значений
 * возвращается дополнительный фильтр для запроса
 * @param servicePrefix {string | undefined}
 */
export function checkServicePrefix(servicePrefix) {
  let prefix = {Params: {}};
  if (servicePrefix === system.SERVICE_PREFIX.ADMIN) {
    prefix = {Params: {IsAdminUI: 1}};
  }
  if (servicePrefix === system.SERVICE_PREFIX.CONFIGURATOR) {
    prefix = {Params: {IsConfiguratorUI: 1}};
  }
  return prefix;
}

const checkLevelName = pathName => {
  if (!pathName) {
    return;
  }
  return {
    [fields.LevelByName]: {'=~': pathName},
  };
};

export const defaultSubsystemFields = [
  fields.ID,
  fields.Name,
  fields.FormID_Name,
  fields.ActionFormName,
  fields.ParentSubsystemID,
  fields.ParentSubsystem_Name,
  fields.ParentSubsystem_Title,
  fields.ParentSubsystemID_ParentSubsystemID_ID,
  fields.ParentSubsystemID_ParentSubsystemID_Title,
  fields.FormID,
  fields.FormID_ViewMode,
  fields.Title,
  fields.Order,
  fields.FormID_Type,
  fields.Icon,
  fields.IsCollapsed,
  fields.FormID_Title,
  fields.DefaultFilter,
  fields.IsFormFilterTemplate,
  fields.IsHasChildren,
  fields.IsGroup,
  fields.TitlePath,
  fields.DocURL
];

export const defaultSorts = [fields.Order];
export const defaultFilters = () => {
  return {
    [fields.IsFormFilterTemplate]: 0,
    ...getUIVersionLS()
  }
};

export const getSubsystemReq = ({servicePrefix, pathName}) => {
  let prefix = checkServicePrefix(servicePrefix);
  const levelName = checkLevelName(pathName);

  return {
    Columns: defaultSubsystemFields,
    Page: -1,
    Sorts: defaultSorts,
    Filters: {
      ...defaultFilters(),
      ...levelName,
    },
    ...prefix,
  };
};

export const getSubsystemReqByID = ({id, servicePrefix}) => {
  let prefix = checkServicePrefix(servicePrefix);
  return {
    Columns: defaultSubsystemFields,
    Page: -1,
    Sorts: defaultSorts,
    Filters: {
      ...defaultFilters(),
      [fields.LevelByID]: id,
    },
    ...prefix,
  };
};

export const getSubsystemReqByParentID = ({servicePrefix, parentID}) => {
  let prefix = checkServicePrefix(servicePrefix);
  return {
    Columns: defaultSubsystemFields,
    Page: -1,
    Sorts: defaultSorts,
    Filters: {
      ...defaultFilters(),
      [fields.ParentSubsystemID]: parentID,
    },
    ...prefix,
  };
};
export const getSubsystemReqByActionFormName = ({servicePrefix, actionFormName}) => {
  let prefix = checkServicePrefix(servicePrefix);
  return {
    Columns: defaultSubsystemFields,
    Page: -1,
    Sorts: defaultSorts,
    Filters: {
      ...defaultFilters(),
      [fields.ActionFormName]: {'=~': actionFormName.toLowerCase()},
    },
    ...prefix,
  };
};

const createMasterFormFilter = ({formName, formID}) => {
  return formName ? {MasterFormName: {'=~': formName.toLowerCase()}} : {MasterFormID: {'=': formID}};
};

/**
 * @param {number} [formID]
 * @param {string} [formName]
 * @param {undefined | number | string} recordID Должен проставляться только для кастомной формы редактирования.
 *  Для кастомной добавления - null. Для всех остальных - undefined.
 * @param {any} filter
 */
export const getFormReq = ({formID, formName, recordID, filter}) => ({
  Filters: filter || {
    ...createMasterFormFilter({formName, formID}),
    RecordID: recordID,
  },
});
