import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DaysOff, SchedulerResource, SchedulerView} from 'components/widgets/Scheduler/types';
import {createAsyncThunk} from '../reduxUtils';
import {fetchData} from 'components/forms/scheduler/utils/getSchedulerDataSource';
import {isDefined, isEmptyValue} from '../SecondaryMethods/typeUtils';

export type SchedulerState = {
  views: SchedulerView[];
  daysOff: DaysOff[];
  resources: SchedulerResource[];
  startDayHour: number;
  endDayHour: number;
};

const createInitialState = (): SchedulerState => {
  return {
    views: [],
    daysOff: [],
    resources: [],
    startDayHour: 0,
    endDayHour: 24
  };
};

const initialState: Record<string, SchedulerState> = {};

const {actions, reducer: schedulerReducer} = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    setViews(
      state,
      {
        payload: {formKey, views}
      }: PayloadAction<{
        formKey: string;
        views: SchedulerState['views'];
      }>
    ) {
      state[formKey] = state[formKey] ?? createInitialState();
      state[formKey].views = views;
    },
    setResources(
      state,
      {
        payload: {formKey, resources}
      }: PayloadAction<{
        formKey: string;
        resources: SchedulerState['resources'];
      }>
    ) {
      state[formKey].resources = resources;
    },
    setDaysOff(
      state,
      {
        payload: {formKey, daysOff}
      }: PayloadAction<{
        formKey: string;
        daysOff: SchedulerState['daysOff'];
      }>
    ) {
      state[formKey].daysOff = daysOff;
    },
    setDayHours(
      state,
      {
        payload: {formKey, endDayHour, startDayHour}
      }: PayloadAction<{
        formKey: string;
        startDayHour?: SchedulerState['startDayHour'];
        endDayHour?: SchedulerState['endDayHour'];
      }>
    ) {
      if (isDefined(startDayHour)) {
        state[formKey].startDayHour = startDayHour!;
      }

      if (isDefined(endDayHour)) {
        state[formKey].endDayHour = endDayHour!;
      }
    },
    updateSchedulers(
      state,
      {
        payload
      }: PayloadAction<
        {
          formKey: string;
          resources?: SchedulerState['resources'];
          startDayHour?: SchedulerState['startDayHour'];
          endDayHour?: SchedulerState['endDayHour'];
        }[]
      >
    ) {
      payload.forEach(({formKey, ...data}) => {
        if (!state[formKey]) return;

        if (data.resources) {
          state[formKey].resources = data.resources;
        }

        if (isDefined(data.startDayHour)) {
          state[formKey].startDayHour = data.startDayHour!;
        }

        if (isDefined(data.endDayHour)) {
          state[formKey].endDayHour = data.endDayHour!;
        }
      });
    }
  }
});

export const fetchSchedulerResources = createAsyncThunk<SchedulerResource[], string>(
  'scheduler/fetchSchedulerResources',
  (formKey, {getState}) => {
    const storedResources = getState().scheduler[formKey]?.resources ?? [];
    const loadPromises = storedResources.map(resource => {
      if (resource.objectName) {
        return fetchData({
          dictObj: resource.objectName,
          columns: [resource.colorExpr, resource.displayExpr, resource.fieldExpr, resource.valueExpr].filter(
            res => !isEmptyValue(res)
          ),
          filter: resource.filter ?? undefined,
          sorts: resource.sortExpr ? [resource.sortExpr] : []
        });
      }
      return Promise.resolve({
        ...resource,
        dataSource: resource.dataSource || []
      });
    });
    return Promise.all(loadPromises).then(resourcesData => {
      return storedResources.map((res, index) => {
        if (!res.objectName) {
          return res;
        }
        return {
          ...res,
          dataSource: resourcesData[index] ?? []
        };
      });
    });
  }
);

export const {setDayHours, updateSchedulers, setDaysOff, setResources, setViews} = actions;

export default schedulerReducer;
