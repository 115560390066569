import {D5FormFilterField} from './D5FormFilterField';
import {FiltersState} from '../storeStates/filtersState';
import {itemGetter} from '../utils';
import {D5Button} from './public-interfaces';
import {TOOLBAR_VIEW_TYPE} from 'services/interfaces/global-interfaces';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';
import Messages from 'services/lang/en';
import {createStoreFilterUtl} from 'utilsOld/routes/routes';
import {D5Form} from './D5Form';
import {D5FormOptions} from '../inner-interfaces';
import {ButtonCreators, ELayoutType} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {TableButtonsState} from '../storeStates/tableButtonsState';
import {getUSDefButton, INewButtonProps} from 'services/SecondaryMethods/formItems/getOneButton';
import {formatMessage} from 'utilsOld/formatMessage';
import {D5Error} from 'services/SecondaryMethods/errors';
import {D5Row} from './listForm/D5Row';

export class D5FormWithToolbar extends D5Form {
  public readonly filterFields: D5FormFilterField[];
  protected _filters: FiltersState;
  private readonly _tableButtons: TableButtonsState;
  private _selectedItems: Record<string, any> = [];


  constructor(props: D5FormOptions) {
    super(props);
    const {filters, sysForm, tableButtons} = props;
    this._tableButtons = tableButtons;
    this.filterFields = this.initFilters(sysForm, filters);
    this._filters = filters;
    this._selectedItems = props.currentForm.selectedRows;
  }

  private getTableD5Button(name: string) {
    return this._tableButtons.getD5Button(name);
  }

  private getTableD5Buttons() {
    return this._tableButtons.getD5Buttons();
  }

  protected getSelectedRows(): D5Row[] {
    return this._selectedItems.map((item: any) => {
      return {key: item.ID, data: item} || [];
    });
  }

  public filterField(name: string) {
    return itemGetter(this.filterFields, (item: any) => item.name === name, ELayoutType.FILTER, name);
  }

  /**
   * Кнопки на форме + кнопки тулбара
   */
  public button(name: string): D5Button | undefined {
    return this.getTableD5Button(name) ?? super.button(name);
  }

  get buttons(): D5Button[] {
    return [...this._buttons, ...this.getTableD5Buttons()];
  }

  get toolBarViewType() {
    return this.currentForm.toolBarViewType;
  }

  set toolBarViewType(toolBarViewType: TOOLBAR_VIEW_TYPE) {
    this.currentForm.toolBarViewType = toolBarViewType;
  }

  get tableButtons(): TableButtonsState {
    return this._tableButtons;
  }

  public async filterUrl() {
    if (this.parentForm) {
      showErrorNotification(Messages.Errors.ParentFormError);
      throw new Error('Can be invoked only on the parent form');
    }
    return await createStoreFilterUtl(this.sysForm, this._filters.get());
  }

  public addButton({name, clickEvent, groupName, icon, title, order}: INewButtonProps) {
    let groupID: number | undefined;
    if (groupName) {
      groupID = this.sysForm.Sys_FormGroups?.find(group => group.Name.toLowerCase() === groupName?.toLowerCase())?.ID;
      if (!groupID) {
        throw new Error(formatMessage(Messages.Errors.GroupDoesNotExist, [groupName]));
      }
    }

    const buttons = this._tableButtons.get();
    const hasSameButton = buttons.some(btn => {
      return btn.name === name && btn.creator === ButtonCreators.USERSCRIPT;
    });

    if (hasSameButton) {
      D5Error.log('E2017', [name]);
      return;
    }

    const newDefButton = getUSDefButton({
      button: {name, clickEvent, groupName, icon, title, order},
      groupID,
      formID: this.sysForm.FormID
    });

    this._tableButtons.rewriteSource([...buttons, newDefButton]);
  }

  public removeButton(name: string) {
    const buttons = this._tableButtons.get();
    const result = buttons.filter(button =>
      button.creator === ButtonCreators.USERSCRIPT ? button.name !== name : true
    );

    if (!result) {
      throw new Error(formatMessage(Messages.Errors.GroupDoesNotExist, [name]));
    }

    this._tableButtons.rewriteSource([...result]);
  }
}
