import {fields, system} from 'services/objects';
import {SysFormFields, SysFormFilterFields} from '../../services/interfaces/sysObjects';
import DisplayType from './DisplayType';
import {ALIGN_STRINGS, FIELD_EDITOR_TYPE, TEXT_ALIGNMENT} from 'services/interfaces/global-interfaces';
import DateSelectMode, {DATE_MODE} from './DateSelectMode';
import {isDefined} from '../../services/SecondaryMethods/typeUtils';
import {BorderStyleStr} from 'services/SecondaryMethods/formItems/itemInterfaces';

const {
  DX_CONTROLS: {
    dxCheckBox,
    dxNumberBox,
    dxDateBox,
    dxTextBox,
    dxTagBox,
    dxSelectBox,
    dxDropDownBox,
    dxButton,
    dxTextArea,
    dxHtmlEditor,
    dxFileUploader,
    dxSwitch,
    dxImage,
    extQrScanner,
    dxDiagram,
    dxRangeSlider,
    dxSlider,
    dxListSelector,
    extDualListSelector,
    extFileViewer,
    dxColorBox,
    dxCodeEditor,
    dxBooleanSelector,
    dxRadioGroup,
    dxButtonGroup,
    dxLabel
  },
  FIELD_TYPE: {BUTTON, COMBINED, BOOL, NUMBER, DATE, DECORATION, TEXT, MEMO, FILE: FILE_FIELD_TYPE},
  DISPLAY_TYPE: {FILE, IMAGE, SWITCH, HTML_EDITOR}
} = system;

export default class BaseField<T extends SysFormFields | SysFormFilterFields> {
  protected _field: T;

  constructor(field: T) {
    this._field = {...field};
  }

  get sourceField() {
    return this._field;
  }

  get ID() {
    return this._field[fields.ID];
  }

  get formID() {
    return this._field[fields.FormID];
  }

  get formIDObjectIDName() {
    return this._field[fields.FormID_ObjectID_Name];
  }

  get name() {
    return this._field[fields.Name];
  }

  get multiselectViewCount() {
    return this._field[fields.MultiselectViewCount];
  }

  get title(): string {
    return this._field[fields.Title] || '';
  }

  get isShowTitle() {
    return Boolean(this._field[fields.IsShowTitle]);
  }

  get viewFormat() {
    return this._field[fields.ViewFormat];
  }

  get editFormat() {
    return this._field[fields.EditFormat];
  }

  get groupID(): number | null {
    return this._field[fields.GroupID] as number;
  }

  get isOneValuePerLine(): boolean {
    return !!this._field[fields.IsOneValuePerLine];
  }

  get TagColorScheme(): number | undefined {
    return this._field[fields.TagColorScheme];
  }

  get TagStylingMode(): number | undefined {
    return this._field[fields.TagStylingMode];
  }

  get LookupTagColorSchemeFieldName(): string | undefined {
    return this._field[fields.LookupTagColorSchemeFieldName];
  }

  get LookupTagStylingModeFieldName(): string | undefined {
    return this._field[fields.LookupTagStylingModeFieldName];
  }

  get isReadOnly() {
    return Boolean(this._field[fields.IsReadOnly]);
  }

  get isVisible() {
    return Boolean(this._field[fields.IsVisible]);
  }

  get isCustomizable() {
    return Boolean(this._field[fields.IsCustomizable]);
  }

  get order() {
    return this._field[fields.Order];
  }

  get titlePosition() {
    switch (this._field[fields.TitlePosition]) {
      case system.TITLE_POSITION.LEFT:
        return 'left';
      case system.TITLE_POSITION.RIGHT:
        return 'right';
      case system.TITLE_POSITION.ONBORDER:
        return 'on_border';
      case system.TITLE_POSITION.FLOATING:
        return 'floating';
      default:
        return 'top';
    }
  }

  get isLabelMode() {
    return (
      this._field[fields.TitlePosition] === system.TITLE_POSITION.ONBORDER ||
      this._field[fields.TitlePosition] === system.TITLE_POSITION.FLOATING
    );
  }

  get titleOrientation() {
    switch (this._field[fields.TitleOrientation]) {
      case system.TITLE_ORIENTATION.VERTICAL:
        return 'vertical';
      case system.TITLE_ORIENTATION.HORIZONTAL:
        return 'horizontal';
      default:
        return 'horizontal';
    }
  }

  get titleFontStyle() {
    return this._field[fields.TitleFontStyle];
  }

  get titleFont(): string | null {
    return this._field[fields.TitleFont];
  }

  get titleAlignment(): number | null {
    return this._field[fields.TitleAlignment];
  }

  get titleColor(): string | null {
    return this._field[fields.TitleColor];
  }

  get titleFontSize(): number | null {
    return this._field[fields.TitleFontSize];
  }

  get borderStyle() {
    switch (this._field[fields.BorderStyle]) {
      case system.BORDER_STYLE.NONE:
        return BorderStyleStr.None;
      case system.BORDER_STYLE.UNDERLINE:
        return BorderStyleStr.Underline;
      default:
        return BorderStyleStr.Default;
    }
  }

  get textFontStyle() {
    return this._field[fields.TextFontStyle];
  }

  get textAlignment() {
    return this._field[fields.TextAlignment];
  }

  /**
   * @returns {string}
   */
  get titleWidth() {
    return this._field[fields.TitleWidth];
  }

  get width() {
    return this._field[fields.FieldWidth];
  }

  get displayType() {
    return this._field[fields.DisplayType];
  }

  get translations() {
    return this._field[fields.Translations];
  }

  get sortValues(): string[] {
    return this._field[fields.LookupSortFieldNames]?.split(',').map(value => value.trim()) ?? [];
  }

  isEnum() {
    return DisplayType.isEnum(this.displayType);
  }

  /**
   * Текстові поля у яких мультиселект стоїть
   */
  get isAnyOf() {
    return this.isServerMultiSelect && DisplayType.isText(this.displayType);
  }

  isQR() {
    return DisplayType.isQR(this.displayType);
  }

  isListSelector() {
    return DisplayType.isListSelector(this.displayType);
  }

  isFieldWithArray() {
    return this.isMultiSelect || this.isAnyOf || this.isPeriod() || this.isRangeSlider();
  }

  /**
   * isEnum, isLookup, hasLinkedObject, isButtonGroup
   */
  get isMultiSelect() {
    return (
      (this.isEnum() || this.isLookup() || this.hasLinkedObject() || this.isButtonGroup()) && this.isServerMultiSelect
    );
  }

  get isServerMultiSelect() {
    return !!this._field[fields.IsMultiselect];
  }

  get isDisabled() {
    return !!this._field[fields.IsDisabled];
  }

  get fieldType(): number {
    throw Error(system.MSGS.MustImplInAChild);
  }

  get required(): boolean {
    throw Error(system.MSGS.MustImplInAChild);
  }

  get displayField(): string | undefined {
    throw Error(system.MSGS.MustImplInAChild);
  }

  /**
   * У справочников должен быть DISPLAY_TYPE.LOOKUP и есть привязанный объект.
   * Раньше было обязательно только наличие привязанного объекта.
   * @returns {boolean}
   */
  isLookup() {
    return this.isLookUpDisplay() && this.hasLinkedObject();
  }

  hasLinkedObject() {
    return !!(this.getLinkedName() && this.displayField);
  }

  isLookUpDisplay() {
    return DisplayType.isLookup(this.displayType);
  }

  getLinkedName(): string | undefined {
    throw Error(system.MSGS.MustImplInAChild);
  }

  getKeyObjFieldName(): string | undefined {
    throw Error(system.MSGS.MustImplInAChild);
  }

  getDisplayObjectFieldIDName(): string {
    throw Error(system.MSGS.MustImplInAChild);
  }

  //----------------------ObjectFieldID----------
  get objectFieldID() {
    return this._field[fields.ObjectFieldID];
  }

  get objectFieldIDName() {
    return this._field[fields.ObjectFieldID_Name];
  }

  get isFile() {
    return !!this._field[fields.ObjectFieldID_IsFile];
  }

  get isArray() {
    return !!this._field[fields.ObjectFieldID_IsArray];
  }

  get objectFieldIDFieldType() {
    return this._field[fields.ObjectFieldID_FieldType];
  }

  get stringLength(): number | undefined {
    return this._field[fields.ObjectFieldID_StringLength];
  }

  isBool() {
    return this.isSwitch() || this.getFieldType() === system.DX_CONTROLS.dxCheckBox || this.isBooleanSelector();
  }

  isDate() {
    return this.fieldType === system.FIELD_TYPE.DATE;
  }

  isDiagram() {
    return DisplayType.isDiagram(this.displayType);
  }

  isHTMLEditor() {
    return DisplayType.isHTMLEditor(this.displayType);
  }

  isDualListSelector() {
    return DisplayType.isDualListSelector(this.displayType);
  }

  isFileViewer() {
    return DisplayType.isFileViewer(this.displayType);
  }

  isRangeSlider() {
    return DisplayType.isRangeSlider(this.displayType);
  }

  isSlider() {
    return DisplayType.isSlider(this.displayType);
  }

  isColorBox() {
    return DisplayType.isColorBox(this.displayType);
  }

  isCodeEditor() {
    return DisplayType.isCodeEditor(this.displayType);
  }

  isBooleanSelector() {
    return DisplayType.isBooleanSelector(this.displayType);
  }

  isRadioGroup() {
    return DisplayType.isRadioGroup(this.displayType);
  }

  isPeriod() {
    return DisplayType.isPeriod(this.displayType);
  }

  isNumber() {
    return this.fieldType === system.FIELD_TYPE.NUMBER;
  }

  isText() {
    return DisplayType.isText(this.displayType);
  }

  isFileControl() {
    return DisplayType.isFileControl(this.displayType);
  }

  isDropDownBox() {
    return DisplayType.isDropDownBox(this.displayType);
  }

  isResizableType() {
    return (
      this.isHTMLEditor() ||
      this.isDiagram() ||
      this.isDualListSelector() ||
      this.isFileViewer() ||
      this.isCodeEditor() ||
      this.isListSelector()
    );
  }

  isDateTime() {
    return this.isDate() && this.isTimeAllowed;
  }

  isSwitch() {
    return this.getFieldType() === system.DX_CONTROLS.dxSwitch;
  }

  isButtonGroup() {
    return DisplayType.isButtonGroup(this.displayType);
  }

  isLabel() {
    return DisplayType.isLabel(this.displayType);
  }

  get dateSelectMode(): DATE_MODE {
    const param = this.parsedDisplayCustomParam?.DateSelectMode;
    if (!isDefined(param)) {
      return this._field[fields.ObjectFieldID_IsTimeAllowed] ? DATE_MODE.DateTime : DATE_MODE.Date;
    }

    return param;
  }

  get isWeekDateMode() {
    return DateSelectMode.isWeek(this.dateSelectMode);
  }

  get isMonthDateMode() {
    return DateSelectMode.isMonth(this.dateSelectMode);
  }

  get isTimeAllowed() {
    return DateSelectMode.isDateTime(this.dateSelectMode);
  }

  get isVisibleOnEditDockPanel() {
    return !!this._field[fields.IsVisibleOnEditDockPanel];
  }

  get numberScale() {
    return this._field[fields.ObjectFieldID_NumberScale] ?? 0;
  }

  get isShowLastValues() {
    return !!this._field[fields.IsShowLastValues];
  }

  get lastValuesCount() {
    return this._field[fields.LastValuesCount] ?? 0;
  }

  get hint() {
    return this._field[fields.Hint];
  }

  get helpArticleGuid() {
    return this._field[fields.HelpArticleGuid] ?? '';
  }

  get docUrl() {
    return this._field[fields.DocURL];
  }

  get lookupSearchKind() {
    return this._field[fields.LookupSearchKind];
  }

  get columnTextAlignment() {
    return this._field[fields.TextAlignment] !== TEXT_ALIGNMENT.AUTODETECT
      ? ALIGN_STRINGS[this._field[fields.TextAlignment]]
      : undefined;
  }

  //----------------------ObjectFieldID----------

  getFieldType(): string {
    switch (true) {
      case this.isEnum():
        return this.isMultiSelect ? dxTagBox : dxSelectBox;
      case this.isQR():
        return extQrScanner;
      case this.isDiagram():
        return dxDiagram;
      case this.isRangeSlider():
        return dxRangeSlider;
      case this.isSlider():
        return dxSlider;
      case this.isListSelector():
        return dxListSelector;
      case this.isDualListSelector():
        return extDualListSelector;
      case this.isFileViewer():
        return extFileViewer;
      case this.isColorBox():
        return dxColorBox;
      case this.isHTMLEditor():
        return dxHtmlEditor;
      case this.isCodeEditor():
        return dxCodeEditor;
      case this.isBooleanSelector():
        return dxBooleanSelector;
      case this.isRadioGroup():
        return dxRadioGroup;
      case this.isButtonGroup():
        return dxButtonGroup;
      case this.isLabel():
        return dxLabel;
    }

    if (this.isLookup()) {
      if (this.isDropDownBox()) {
        return dxDropDownBox;
      }
      return this.isMultiSelect ? dxTagBox : dxSelectBox;
    }

    const checkAdditionalDisplayType = (displayType: number, defaultValue: string) => {
      const fieldType: Record<string, string> = {
        [FILE]: dxFileUploader,
        [IMAGE]: dxImage,
        [SWITCH]: dxSwitch,
        [HTML_EDITOR]: dxHtmlEditor
      };
      return fieldType[displayType] ?? defaultValue;
    };

    const {
      [fields.LineCount]: lineCount,
      [fields.Height]: height,
      [fields.MaxHeight]: maxHeight,
      [fields.IsAdaptiveHeight]: isAdaptiveHeight
    } = this._field as SysFormFields;

    const isTextAreaDisplayType =
      isAdaptiveHeight || (lineCount ?? 0) >= 1 || (height ?? 0) >= 1 || (maxHeight ?? 0) >= 1 || lineCount === -1;

    const createFieldType: Record<string | number, string> = {
      [COMBINED]: COMBINED,
      [BUTTON]: dxButton,
      [DECORATION]: DECORATION,
      [DATE]: dxDateBox,
      [NUMBER]: checkAdditionalDisplayType(this.displayType, dxNumberBox),
      [FILE_FIELD_TYPE]: checkAdditionalDisplayType(this.displayType, dxFileUploader),
      [BOOL]: checkAdditionalDisplayType(this.displayType, dxCheckBox),
      [TEXT]: checkAdditionalDisplayType(this.displayType, isTextAreaDisplayType ? dxTextArea : dxTextBox),
      [MEMO]: checkAdditionalDisplayType(this.displayType, isTextAreaDisplayType ? dxTextArea : dxTextBox)
    };

    return createFieldType[this.fieldType] || dxTextBox;
  }

  d5EditorType() {
    const {
      IMAGE,
      BOOL_CB,
      MULTI_SELECT,
      NUMBER,
      DATE,
      TEXT,
      TEXT_AREA,
      SELECT,
      DROP_DOWN_BOX,
      HTML_EDITOR,
      FILE,
      SWITCH,
      QR_SCANNER,
      DIAGRAM,
      RANGE_SLIDER,
      SLIDER,
      LIST_SELECTOR,
      DUAL_LIST_SELECTOR,
      FILE_PREVIEW,
      COLOR_BOX,
      CODE_EDITOR,
      BOOLEAN_SELECTOR,
      RADIO_GROUP,
      BUTTON_GROUP,
      LABEL
    } = FIELD_EDITOR_TYPE;

    const createEditorType: Record<string, FIELD_EDITOR_TYPE> = {
      [dxImage]: IMAGE,
      [dxCheckBox]: BOOL_CB,
      [dxNumberBox]: NUMBER,
      [dxDateBox]: DATE,
      [dxTextBox]: TEXT,
      [dxTextArea]: TEXT_AREA,
      [dxTagBox]: MULTI_SELECT,
      [dxSelectBox]: SELECT,
      [dxDropDownBox]: DROP_DOWN_BOX,
      [dxHtmlEditor]: HTML_EDITOR,
      [dxSwitch]: SWITCH,
      [dxFileUploader]: FILE,
      [extQrScanner]: QR_SCANNER,
      [dxDiagram]: DIAGRAM,
      [dxRangeSlider]: RANGE_SLIDER,
      [dxSlider]: SLIDER,
      [dxListSelector]: LIST_SELECTOR,
      [extDualListSelector]: DUAL_LIST_SELECTOR,
      [extFileViewer]: FILE_PREVIEW,
      [dxColorBox]: COLOR_BOX,
      [dxCodeEditor]: CODE_EDITOR,
      [dxBooleanSelector]: BOOLEAN_SELECTOR,
      [dxRadioGroup]: RADIO_GROUP,
      [dxButtonGroup]: BUTTON_GROUP,
      [dxLabel]: LABEL
    };

    return createEditorType[this.getFieldType()];
  }

  getFieldFormatType() {
    const type = this.getFieldType();
    const withTime = this.isTimeAllowed;
    const {DATE, DATETIME} = FIELD_EDITOR_TYPE;

    switch (type) {
      case DATE:
      case DATETIME:
      case system.DX_CONTROLS.dxDateBox: {
        if (withTime) {
          return DATETIME;
        }
        return DATE;
      }
      default:
        return null;
    }
  }

  getLabelType(): string | undefined {
    const {NUMBER, DATE, TEXT} = FIELD_EDITOR_TYPE;

    if (this.isNumber()) {
      return NUMBER;
    }
    if (this.isDate()) {
      return DATE;
    }
    if (this.isText()) {
      return TEXT;
    }

    return undefined;
  }

  getFieldFormat(): string | undefined {
    const numberScale = this.numberScale;
    const type = this.isLabel() ? this.getLabelType() : this.getFieldType();

    const {NUMBER, DATE, DATETIME, TEXT} = FIELD_EDITOR_TYPE;

    switch (type) {
      case NUMBER:
      case system.DX_CONTROLS.dxNumberBox:
        if (this.viewFormat) {
          return this.viewFormat;
        }
        if (this.editFormat) {
          return this.editFormat;
        }

        if (numberScale > 0) {
          return '#0.' + Array.from({length: numberScale}).fill(0).join('');
        }
        //если тут передать какое-то форматирование, то невозможно удалить значение с фильтра
        //всегда остается 0
        return;
      case DATE:
      case DATETIME:
      case system.DX_CONTROLS.dxDateBox:
        if (this.viewFormat) {
          return this.viewFormat;
        }
        if (this.editFormat) {
          return this.editFormat;
        }
        break;
      case TEXT:
      case system.DX_CONTROLS.dxTextBox:
        return this.editFormat || this.viewFormat;
      default:
        return undefined;
    }
  }

  get parsedDisplayCustomParam() {
    return JSON.parse(this.displayCustomParam);
  }

  get displayCustomParam(): string {
    return this._field[fields.DisplayCustomParam] || '{}';
  }
}
