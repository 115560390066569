import {iconLibByIcon} from '../../../../utilsOld/icons';
import {createIconPart} from './combinedColumnsUtils';
import {system} from '../../../../services/objects';
import {SysFormFields} from '../../../../services/interfaces/sysObjects';
import {FIELDS} from './CombinedColumn';

const {
  DEFAULT_LOCALS: {CELL_INNER_PADDING}
} = system;

interface IIconField {
  iconField: SysFormFields;
  row: Record<string, any>;
  container: HTMLElement;
}

export const setIconField = ({iconField, row, container}: IIconField) => {
  const {IconSize} = iconField;
  const iconFieldValue = row.data[iconField.Name];
  const {iconLibrary = '', iconClass = ''} = iconLibByIcon(iconFieldValue);
  if (iconClass) {
    const size = IconSize && IconSize > 0 ? IconSize : undefined;
    let iconPart = createIconPart(iconClass, size, iconLibrary);
    iconPart.style.paddingRight = CELL_INNER_PADDING;
    if (IconSize && IconSize > 0) {
      iconPart.style.fontSize = `${IconSize}rem`;
      iconPart.style.height = `${IconSize}rem`;
    }
    iconPart.classList.add(FIELDS.ICON_FIELD);
    container.append(iconPart);
  }
};
