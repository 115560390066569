import {Column} from 'components/TableCoreOld/columns/index';
import {createIconPart} from './combinedColumn/combinedColumnsUtils';
import {iconLibByIcon} from 'utilsOld/icons';


export default class IconColumn extends Column {
  prop () {
    const {alignment}= super.prop();

    return {
      ...super.prop(),
      allowHeaderFiltering: false,
      cellTemplate: (cellElement, { value }) => {
        const {iconLibrary = '', iconClass = ''} = iconLibByIcon(value);
        const rowIconBlock = createIconPart(iconClass, undefined, iconLibrary);
        if(!alignment){
          rowIconBlock.style.display = 'flex';
          rowIconBlock.style.alignItems = 'center';
          rowIconBlock.style.justifyContent = 'center';
        }
        cellElement.appendChild(rowIconBlock);
      }
    };
  }
}
