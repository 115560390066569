import {fields} from 'services/objects';
import {FormType, FormViewMode} from 'services/interfaces/global-interfaces';
import {SysSubSystem} from 'services/interfaces/sysObjects/SysSubSystem';

export default class Subsystem {
  private _rawSubsystem: SysSubSystem;

  constructor(subsystem: SysSubSystem) {
    this._rawSubsystem = subsystem;
  }

  public get id(): number {
    return this._rawSubsystem[fields.ID];
  }

  public get actionFormName(): string | null {
    return this._rawSubsystem[fields.ActionFormName];
  }
  public get isGroup(): number {
    return this._rawSubsystem[fields.IsGroup];
  }

  public get parentSubsystemID(): number | null {
    return this._rawSubsystem[fields.ParentSubsystemID];
  }
  public get parentSubsystemName(): string | null {
    return this._rawSubsystem[fields.ParentSubsystem_Name];
  }
  public get parentSubsystemTitle(): string | null {
    return this._rawSubsystem[fields.ParentSubsystem_Title];
  }
  public get superiorParentTitle(): string | null {
    return this._rawSubsystem[fields.ParentSubsystemID_ParentSubsystemID_Title];
  }
  public get superiorParentID(): number | null {
    return this._rawSubsystem[fields.ParentSubsystemID_ParentSubsystemID_ID];
  }
  public get markerSubsystems(): string | undefined {
    return this._rawSubsystem[fields.MarkerSubsystems];
  }
  public get isHasChildren(): number | null {
    return this._rawSubsystem[fields.IsHasChildren];
  }

  public get isFormFilterTemplate(): boolean {
    return !!this._rawSubsystem[fields.IsFormFilterTemplate];
  }

  public get name(): string {
    return this._rawSubsystem[fields.Name];
  }

  public get order(): number | null {
    return this._rawSubsystem[fields.Order];
  }

  public get title(): string {
    return this._rawSubsystem[fields.Title];
  }

  public get icon(): string | null {
    return this._rawSubsystem[fields.Icon];
  }

  public get formID(): string | null {
    return this._rawSubsystem[fields.FormID];
  }

  public get formName(): string | null {
    return this._rawSubsystem[fields.FormID_Name];
  }

  public get formTitle(): string | null {
    return this._rawSubsystem[fields.FormID_Title];
  }

  public get formType(): FormType | null {
    return this._rawSubsystem[fields.FormID_Type];
  }

  public get formViewMode(): FormViewMode | null {
    return this._rawSubsystem[fields.FormID_ViewMode];
  }
}
