import {system} from 'services/objects';
import {COLUMN_WIDTH} from './columnSizes';
import TableInstance from '../TableInstance';
import TreeListInstance from '../TreeListInstance';
import HeaderFilterDataSource from './HeaderFilterDataSource';
import {getTranslatedTitle} from 'services/SecondaryMethods/formItems/utils';
import createValidationRules from './validation/createValidationRules';
import {columnWidthByType, getSortOrder} from './utils';
import {getHeaderCellTemplate} from './getHeaderCellTemplate';
import {createFieldTestId} from '../../../utilsOld/createDataTestId';

const {
  FIELD_TYPE: {NUMBER, BOOL, TEXT, DATE}
} = system;

class Column {
  /**
   * @type {FormField}
   */
  field;

  /**
   * @param {Object} arg
   * @param {FormField} arg.field
   * @param {string} arg.keyField
   * @param {number} arg.isFixedColumnOrder
   * @param {number} arg.isFixedOrder
   * @param {number} arg.isColumnAutoWidth
   * @param {Locale} arg.regSetting
   * @param {string} arg.objectName
   * @param {number} arg.maxLineCount
   * @param {string | null} arg.fixedOrderField
   * @param {string} arg.formKey
   * @param {SysFormFields[]} arg.viewSource
   * @param {number | undefined} arg.parentFormID
   * @param {string[]} arg.forbidFiltering - массив строк ObjectFieldID.Name
   */
  constructor({
    field,
    keyField,
    regSetting,
    objectName,
    isColumnAutoWidth,
    isFixedColumnOrder,
    isFixedOrder,
    isNestedTable,
    parentFormID,
    maxLineCount,
    viewSource,
    forbidFiltering,
    fixedOrderField,
    formKey,
    checkFieldID,
  }) {
    this.field = field;
    this.keyField = keyField;
    this.regSetting = regSetting;
    this.maxLineCount = maxLineCount;
    this.isFixedColumnOrder = isFixedColumnOrder;
    this.isFixedOrder = isFixedOrder;
    this.isColumnAutoWidth = isColumnAutoWidth;
    this.objectName = objectName;
    this.isNestedTable = isNestedTable;
    this.viewSource = viewSource;
    this.formKey = formKey;
    this.parentFormID = parentFormID;
    this.forbidFiltering = forbidFiltering;
    this.fixedOrderField = fixedOrderField;
    this.checkFieldID = checkFieldID;
  }

  isFilterEnabledByAddFilter(fieldDataName) {
    return !this.forbidFiltering.includes(fieldDataName);
  }

  isRequiredField() {
    let isRequired = this.field.required;
    const name = this.field.name;

    if (this.field.isBool() || this.keyField === this.field.objectFieldIDName) {
      isRequired = false;
    }

    return isRequired && name !== this.keyField;
  }

  /**
   * @returns {number | undefined}
   */
  getColumnWidth() {
    //default colWidth in DataGrid = undefined
    if (this.isColumnAutoWidth) {
      return undefined;
    }
    if (this.field.isBooleanSelector()) {
      return COLUMN_WIDTH.BOOL_SELECTOR.DEFAULT;
    }

    return columnWidthByType(this.field.fieldType);
  }

  getMinWidth() {
    const type = this.field.fieldType;
    const allowedTypes = [NUMBER, BOOL, TEXT, DATE];
    const titleOrientation = this.field.titleOrientation;
    if (titleOrientation === 'vertical') {
      return COLUMN_WIDTH.VERTICAL.MIN;
    }
    if (this.field.isBooleanSelector()) {
      return COLUMN_WIDTH.BOOL_SELECTOR.MIN;
    }
    return allowedTypes.includes(type) ? COLUMN_WIDTH[type].MIN : COLUMN_WIDTH.OTHER_TYPES.MIN;
  }

  headerFilterValueGetter = row => ({text: row[this.field.name], value: row[this.field.name]});

  headerFilterLoadFun = instance => {
    const ds = new HeaderFilterDataSource({
      loadRows: instance.getAllRowsData.bind(instance),
      valueGetter: this.headerFilterValueGetter
    });
    return ds.createLoadFun();
  };

  createHeaderFilter() {
    return {
      dataSource: ({component, dataSource}) => {
        dataSource.paginate = false;
        const instance = TableInstance.classOf(component)
          ? new TableInstance(component)
          : new TreeListInstance(component);
        dataSource.load = this.headerFilterLoadFun(instance);
      }
    };
  }

  prop() {
    let dataField = this.field.name;
    let dictObj = this.field.getLinkedName();
    let dictKeyFld = this.field.getKeyObjFieldName();
    let dictDisplayFld = this.field.displayField;
    let displayCustomParam = this.field.displayCustomParam;

    let sortOrder = getSortOrder({
      sortByDefault: this.field.sortByDefault,
      isFixedOrder: this.isFixedOrder,
      fixedOrderField: this.fixedOrderField,
      isFixedOrderField: this.fixedOrderField === dataField
    });

    const multiField = this.field.isMultiValueField;

    const isFixedColumnOrder = !!this.isFixedColumnOrder;
    let allowReordering = !isFixedColumnOrder;
    let allowFiltering = this.isFilterEnabledByAddFilter(this.field.objectFieldIDName);

    let isReadOnly =
      this.field.isReadOnly ||
      [this.field.objectFieldIDName].includes(this.keyField) ||
      this.fixedOrderField === this.field.name;

    const visible = this.field.isVisible && !multiField;

    const addonForDynamicBase = multiField ? {baseFieldIsVisible: this.field.isVisible} : {};

    const caption = getTranslatedTitle(this.field.translations) || this.field.title;
    const allowSorting = !!this.field.isSortingAllowed;

    const titleOrientation = this.field.titleOrientation;

    return {
      ...addonForDynamicBase,
      ID: this.field[this.keyField],
      allowFiltering,
      allowSorting,
      allowReordering: allowReordering,
      isReadOnly,
      allowFixing: true,
      caption,
      dataField,
      alignment: this.field.columnTextAlignment,
      name: this.field.name,
      fixed: this.field.isFixedLeft,
      fixedPosition: this.field.isFixedLeft ? 'left' : undefined,
      dataType: 'string',
      displayCustomParam,
      hint: this.field.hint,
      helpArticleGuid:this.field.helpArticleGuid,
      docUrl: this.field.docUrl,
      isShowTitle: this.field.isShowTitle,
      /**
       * @param {HTMLElement} textEl
       * @param column
       * @param {dxDataGrid} component
       */
      headerCellTemplate: getHeaderCellTemplate(titleOrientation, this.field),
      filterOperations: [],
      showInColumnChooser: !multiField,
      headerFilter: {
        search: {enabled: true}
      },
      totalFunction: this.field.totalFunction,
      sortOrder: sortOrder,
      sortIndex: sortOrder ? 0 : undefined,
      groupParentID: this.field.groupID,
      userData: {
        allowFiltering: allowFiltering,
        dictionaryDisplayFld: dictDisplayFld,
        dictionaryKeyFld: dictKeyFld,
        dictionaryObj: dictObj,
        groupID: this.field.groupID,
        groupTitle: this.field.groupTitle,
        objectFieldIdName: this.field.objectFieldIDName,
        maxLineCount: this.maxLineCount,
        fieldType: this.field.fieldType,
        maxLength: this.field.stringLength,
        baseMultiField: multiField,
        formID: this.field.getFormID(),
        viewFormat: this.field.viewFormat,
        numberScale: this.field.numberScale,
        dataTestId: createFieldTestId(this.field.getFormID(), this.field.name)
      },
      required: this.isRequiredField(),
      validationRules: createValidationRules({
        formName: this.field.getFormID(),
        formKey: this.formKey,
        caption
      }),
      visible: visible,
      isVisibleOnEditDockPanel: this.field.isVisibleOnEditDockPanel,
      isCustomizable: this.field.isCustomizable,
      visibleIndex: this.field.order,
      minWidth: this.getMinWidth(),
      width: this.getColumnWidth()
    };
  }
}

export default Column;
