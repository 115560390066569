import {D5BaseField} from 'middlewares/userScript/elems/D5BaseField';
import {formatFilterToStore, formatToAPI, getFilterName} from 'services/SecondaryMethods/filterUtils';
import {FiltersState} from 'middlewares/userScript/storeStates/filtersState';
import {ItemsState} from 'middlewares/userScript/storeStates/itemsState';
import {D5Error} from 'services/SecondaryMethods/errors';
import {validateEnumDataSource} from 'middlewares/userScript/utils';
import {isEmptyObject, isNull, isObjectOrNull} from 'services/SecondaryMethods/typeUtils';
import {SysForm} from 'services/interfaces/sysObjects';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import {IFilterOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {FILTER_OPERATIONS} from 'services/interfaces/global-interfaces';

declare interface D5FormFilterFieldOptions {
  name: string;
  id?: number;
  formItems: ItemsState;
  sysForm: SysForm;
  filters: FiltersState;
}

export class D5FormFilterField extends D5BaseField {
  private readonly filters: FiltersState;
  private readonly filterName: string;
  private _item: ILayoutItem<IFilterOptions>;
  private readonly sysForm: SysForm;
  private readonly _cachedValue: any;

  constructor({filters, formItems, name, id, sysForm}: D5FormFilterFieldOptions) {
    super({formItems, name, id: id!});
    this.sysForm = sysForm;
    this.index = this.collection.findIndex(item => item.name === name && LayoutType.isFilter(item.itemType));
    if (this.index === -1) {
      throw new Error(`Filter ${name} is not found`);
    }

    this._item = this.collection.item(this.index);
    this._id = id ?? this._item.id;

    this.filters = filters;
    this.filterName = getFilterName({
      objectFieldIDName: this._item.options.objectFieldID,
      name: this.name
    });
    //Зберігаємо значення до виконання скрипта. Якщо значення пусте, то при установці defaultValue/defaultOperation
    // установлюються також відповідні значення у саме поле
    this._cachedValue = this.value ?? '';
  }

  protected getEnumDataSource() {
    return this.filters.item(this.filterName)?.dataSource ?? null;
  }

  private get isValueMustBeArray() {
    return this.getOption('isAnyOf') || this.getOption('isMultiSelect');
  }


  get value() {
    const val = this.filters.item(this.filterName)?.value;

    //у тагбоксів повертає пустий масив, а в скприатах очікують null
    if (this.isValueMustBeArray && !val?.length) {
      return null
    }

    return val;
  }

  get isCustomizable() {
    return !!this.filters.item(this.filterName)?.isCustomizable;
  }

  set isCustomizable(isCustomizable: boolean) {
    const item = this.filters.item(this.filterName);
    if (item) {
      item.isCustomizable = isCustomizable;
    }
  }

  set value(value: any) {
    const item = this.filters.item(this.filterName);
    if (item) {
      item.value = value;
      item.displayValue = undefined;
    }

    try {
      this.setOption('value', value);
    } catch (e) {
      /*Эта штука обновляет фильтры на лейауте.
      Если такого нет, то возможно обновляется фильтр в таблице*/
    }
  }

  get defaultValue() {
    return this.getOption('defaultValue');
  }

  set defaultValue(value: any) {
    this.setOption('defaultValue', value);
    if (this._cachedValue === '') this.value = value;
  }

  get defaultOperation() {
    return this.getOption('defaultOperation');
  }

  set defaultOperation(operation: FILTER_OPERATIONS) {
    this.setOption('defaultOperation', operation);
    if (this._cachedValue === '') this.operation = operation;
  }

  get operation() {
    const item = this.filters.item(this.filterName);
    // @ts-ignore
    return item.operation;
  }

  set operation(operation: FILTER_OPERATIONS) {
    const item = this.filters.item(this.filterName);
    if (item) {
      item.operation = operation;
    }
    try {
      this.setOption('realOperation', operation);
    } catch (e) {
      /*Эта штука обновляет фильтры на лейауте.
      Если такого нет, то возможно обновляется фильтр в таблице*/
    }
  }

  get displayValue() {
    return this.filters.item(this.filterName)?.displayValue;
  }

  set datasource(arr: Array<any>) {
    const fieldType = this._item.options.fieldType;
    const isInvalid = validateEnumDataSource(arr, fieldType);

    if (isInvalid) {
      D5Error.log('E1015', [this.name]);
    }

    const filterItem = this.filters.item(this.filterName)!;
    filterItem.dataSource = arr;
  }

  get valueAsObject() {
    const storeFilters: any = {
      [this._item.options.objectFieldID]: {
        requestField: this._item.options.objectFieldID,
        value: this.value,
        operation: this.operation
      }
    };

    return formatToAPI({
      storeFilters,
      sysForm: this.sysForm as SysForm
    });
  }

  set valueAsObject(filterObject: Record<string, any>) {
    if (!isObjectOrNull(filterObject)) {
      throw new TypeError('filterObject must be an object');
    }

    if (isNull(filterObject) || isEmptyObject(filterObject)) {
      this.operation = FILTER_OPERATIONS.equal;
      this.value = null;
      return;
    }

    const formattedFilter = formatFilterToStore(
      {
        [this._item.options.objectFieldID || this._item.name]: filterObject
      },
      this.sysForm as SysForm
    );

    const {
      [this._item.options.objectFieldID || this.name]: {operation, value}
    } = formattedFilter;

    this.operation = operation;
    this.value = value;
  }
}
