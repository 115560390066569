import {userReducer} from './signUser/reducer';
import userSettingsReducer from './userSettings/reducer';
import {requestsReducer} from './requests/reducer';
import overlayReducer from './overlay/reducer';
import {currentFormsReducer} from './currentForms/reducer';
import {modalReducer} from './modal/reducer';
import {filtersReducer} from './filters/reducer';
import {masterDetailReducer} from './masterDetail/reducer';
import {tablesReducer} from './tables/reducer';
import {loadingReducer} from 'services/loading/reducer';
import {dialogReducer} from './dialog/reducer';
import snackbarReducer from './snackbar/reducer';
import {filterTemplatesReducer} from './filterTemplates/reducer';
import {userScriptFilesReducer} from './userScriptFiles/reducer';
import secondaryDrawerReducer from './secondaryDrawer/reducer';
import {hintReducer} from './hint/reducer';
import {editorButtonsReducer} from './editorButtons/reducer';
import {userDataReducer} from './userData/reducer';
import kanbanReducer from './kanban/reducer';
import {clearIndicatorReducer} from './clearIndicators/reducer';
import {elementsUserSettingsReducer} from './elementsUserSettings/reducer';
import schedulerReducer from './scheduler/reducer';
import tileViewReducer from './tileView/reducer';
import listViewReducer from './listView/reducer';
import applicationsReducer from './applications/reducer';
import {hiddenButtonsReducer} from './hiddenButtons/reducer';
import dataSourceCacheStoreReducer from './dataSourceCacheStore/reducer';

const rootReducer = {
  user: userReducer,
  userSettings: userSettingsReducer,
  requests: requestsReducer,
  overlay: overlayReducer,
  currentForm: currentFormsReducer,
  modal: modalReducer,
  filters: filtersReducer,
  masterDetail: masterDetailReducer,
  tables: tablesReducer,
  loading: loadingReducer,
  dialog: dialogReducer,
  snackbars: snackbarReducer,
  filterTemplates: filterTemplatesReducer,
  userScriptFiles: userScriptFilesReducer,
  secondaryDrawer: secondaryDrawerReducer,
  hint: hintReducer,
  editorButtons: editorButtonsReducer,
  hiddenButtons: hiddenButtonsReducer,
  userData: userDataReducer,
  kanban: kanbanReducer,
  scheduler: schedulerReducer,
  tileView: tileViewReducer,
  listView: listViewReducer,
  clearIndicators: clearIndicatorReducer,
  elementsUserSettings: elementsUserSettingsReducer,
  applications: applicationsReducer,
  dataSourceCacheStore: dataSourceCacheStoreReducer,
};

export default rootReducer;
