import {createRequiredMark} from 'services/tables/utils';
import MouseEventHelper from 'utilsOld/MouseEventHelper';
import {createEventPromise} from 'services/currentForms/userScriptActions';
import type {FormField} from 'utilsOld/systemObjects';
import type DataGrid from 'devextreme/ui/data_grid';
import type {GridColumn} from '../column-interfaces';

/**
 * Перехоплення кліку по заголовку робиться тут, а не в onCellClick, тому що оброблювач onCellClick повішаний на елемент table,
 * а сортування на tr і коли клікаєш по комірці то сортування спрацьовує раніше ніж onCellClick.
 * Причина чому потрібно перехоплювати сортування.
 *  - Коли сортуєш, то необхідно зняти виділення всіх рядків. Через це спрацьовує onSelectionChanged.
 *  - Сортування запускає завантажування даних і onDataLoaded.
 *  - onSelectionChanged спрацьовує зі старими значеннями, а onDataLoaded з новими й таблиця зациклюється при оновленні колонок.
 *
 * Вирішення.
 * 1. Перехопити клік на комірці.
 * 2. Очистити обрані рядки, якщо вони є
 * 3. Тільки після того, як виконається onSelectionChanged ще раз викликати сортування.
 */
function handleHeaderCellTemplateClick(cellElement: HTMLTableCellElement, component: DataGrid, columnName: string) {


  //Ознака що клік вже в процесі
  let clickProcessing = false;
  //Ознака що потрібно запускати стандартний клік ДХ
  let executeNativeClick = false;

  cellElement.addEventListener('click', e => {
    const column: GridColumn =  component.columnOption(columnName);

    if (!column?.allowSorting) {
      return;
    }

    // @ts-ignore
    if (e.target?.classList.contains('dx-checkbox-icon')) {
      return;
    }

    if (executeNativeClick) {
      executeNativeClick = false;
      return;
    }

    e.stopPropagation();
    if (clickProcessing) {
      return;
    }

    const eventHelper = new MouseEventHelper(e);
    const {event, eventPromise} = createEventPromise();

    /**
     * _eventsStrategy - приватное свойсво dxDataGrid, получилось сделать только так.
     * trigger разрешает использовать только стандартные события dx.
     * @see DTableStore.setOptionsHook
     * @see DTableStore.handleHeaderCellClick
     */
    // @ts-ignore
    if (!component._eventsStrategy.hasEvent('customHeaderCellClick')) {
      return;
    }
    clickProcessing = true;
    // @ts-ignore
    component._eventsStrategy.fireEvent('customHeaderCellClick', [
      {
        event: {
          ...event
        }
      }
    ]);

    /**
     *  Після скидання вибраних рядків в події customHeaderCellClick потрібно ще раз клікнути на комірку,
     *  щоб спрацювало рідне сортування.
     *  @see DTableStore.handleHeaderCellClick
     */
    eventPromise.then(() => {
      clickProcessing = false;
      executeNativeClick = true;
      cellElement.dispatchEvent(
        new MouseEvent('click', {
          ctrlKey: eventHelper.ctrlPressed,
          shiftKey: eventHelper.shiftPressed,
          bubbles: true,
          cancelable: true
        })
      );
    });
  });
}

export function getHeaderCellTemplate(titleOrientation: 'vertical' | 'horizontal', field: FormField) {
  return (textEl: HTMLElement, {column, component}: {column: GridColumn; component: DataGrid}) => {
    const parentContainer = textEl.parentElement;

    if (parentContainer && textEl) {
      // треба виставити цей клас, щоб елемент виділявся і не було проблем з групуванням заголовків
      parentContainer.classList.add('template-cell');

      const filterIndicator = !!parentContainer.getElementsByClassName('dx-header-filter')[0];
      const sortIndicator = !!parentContainer.getElementsByClassName('dx-sort')[0];

      const withIndicators = filterIndicator && sortIndicator;
      const oneIndicator = withIndicators ? false : filterIndicator || sortIndicator;

      textEl.classList.add(withIndicators ? 'with-indicators' : oneIndicator ? 'one-indicator' : 'without-indicators');
    }

    if (titleOrientation === 'vertical') {
      parentContainer?.classList.add('vertical-header-container');

      textEl.classList.add('vertical-header');
      const span = document.createElement('span');
      span.innerText = column.isShowTitle ? column.caption : '';
      textEl.appendChild(span);
    } else {
      textEl.innerText = column.isShowTitle ? column.caption : '';
    }
    // const dragIcon = document.createElement('i');
    // dragIcon.className = 'drag-icon';
    // textEl.insertAdjacentElement('beforebegin', dragIcon);
    column.required && createRequiredMark(textEl, field.getFormID(), field.name);
    const cellElement = textEl.closest('td');
    handleHeaderCellTemplateClick(cellElement!, component, column.name);
  };
}
