import {
  makeCurrFormSelector,
  makeEditableRowDataSelector,
  makeNestedDataSelector
} from 'services/currentForms/selectors';
import {makeFilterSelector} from 'services/filters/selectors';
import {makeColumnsSelector, makeSummarySelector, makeTableButtonsSelector} from 'services/tables/selectors';
import {D5ColumnsCollection} from '../elems/listForm/D5ColumnsCollection';
import {TableButtonsState} from 'middlewares/userScript/storeStates/tableButtonsState';
import {findSysForm} from 'services/SecondaryMethods';
import {IButtonOptions, IFocusedField, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {FiltersState} from 'middlewares/userScript/storeStates/filtersState';
import {GroupsState} from 'middlewares/userScript/storeStates/groupsState';
import {ItemsState} from 'middlewares/userScript/storeStates/itemsState';
import {EditableRowDataState} from 'middlewares/userScript/storeStates/editableRowDataState';
import {FILTER_SENDERS, RowPerPage, USER_EVENT_TYPE} from 'services/interfaces/global-interfaces';
import {NestedDataState} from '../storeStates/nestedDataState';
import {SysForm} from 'services/interfaces/sysObjects';
import {GridColumn} from 'components/TableCoreOld/column-interfaces';
import {RowsToUpdate} from 'services/tables/interfaces';
import {FilterStateObject} from 'services/filters/types';
import {EditorButtonsState} from '../storeStates/editorButtonsState';
import {makeAllCurrentEditorButtonsSelector} from 'services/editorButtons/selectors';
import {ApiOperationState} from '../storeStates/apiOperation';
import {CurrentFormState} from 'services/currentForms/types';
import {RootState} from 'store';
import {ElementsUserSettingsState} from '../storeStates/elementsUserSettingsState';
import {makeKanbanCardItemsSelector} from 'services/kanban/selectors';
import {languagesSelector, regionalSettings as regSelector} from 'services/overlay/selectors';
import {ILanguage} from 'services/overlay/reducer';
import {SummaryDataState} from '../storeStates/SummaryDataState';
import {makeSchedulerByFormKeySelector} from 'services/scheduler/selectors';
import {createDraft} from 'immer';
import {UserScriptSchedulerResources} from '../inner-interfaces';
import {makeTileViewItemsSelector} from 'services/tileView/selectors';
import {makeListViewItemsSelector} from 'services/listView/selectors';
import {makeElementsUserSettingsSelector} from '../../../services/elementsUserSettings/reducer';

const createFilterDocPanel = ({formKey, state}: {state: RootState; formKey: string}): FilterDocPanel => {
  const currentForm = makeCurrFormSelector()(state, formKey);
  let filters = (makeFilterSelector()(state, formKey) || {}) as Record<string, FilterStateObject>;
  let filterItems = new ItemsState(currentForm.items || []);
  return {
    formKey,
    formID: formKey,
    filterItems,
    filters: new FiltersState(filters)
  };
};

function getFormProps({state, formID, sysForm}: {state: RootState; formID: string; sysForm: SysForm}) {
  let currentForm = makeCurrFormSelector()(state, formID);
  const formKey = currentForm.formKey;

  let filters = (makeFilterSelector()(state, formKey) || {}) as Record<string, FilterStateObject>;
  let columns = (makeColumnsSelector()(state, formKey) || []) as GridColumn[];
  let tableButtons = makeTableButtonsSelector()(state, formKey) as ILayoutItem<IButtonOptions>[];

  const editableRowData = makeEditableRowDataSelector()(state, formID);
  const editableRowDataState = editableRowData && new EditableRowDataState(editableRowData);
  const apiOperationState = new ApiOperationState(currentForm.apiOperation);
  const editorButtons = makeAllCurrentEditorButtonsSelector()(state, formKey);
  const elementsUserSettings = makeElementsUserSettingsSelector()(state, formKey);
  const kanbanCardItems = makeKanbanCardItemsSelector()(state, formKey);
  const elementsUserSettingsState = new ElementsUserSettingsState(elementsUserSettings || {});
  const summaryData = makeSummarySelector()(state, formKey);
  const schedulerState = makeSchedulerByFormKeySelector()(state, formKey);
  const regSettings = regSelector(state);
  const summaryDataState = summaryData && new SummaryDataState(summaryData);
  const tileViewItems = makeTileViewItemsSelector()(state, formKey);
  const listViewItems = makeListViewItemsSelector()(state, formKey);

  return {
    formKey,
    schedulerResources: createDraft(schedulerState?.resources ?? []),
    schedulerStartDayHour: schedulerState?.startDayHour,
    schedulerEndDayHour: schedulerState?.endDayHour,
    currentForm: {...currentForm},
    editableRowData: editableRowDataState,
    elementsUserSettings: elementsUserSettingsState,
    apiOperation: apiOperationState,
    filters: new FiltersState(filters),
    kanbanCardItems: new ItemsState(kanbanCardItems || []),
    columns: new D5ColumnsCollection(columns || [], editableRowDataState, summaryDataState, regSettings, sysForm),
    tableButtons: new TableButtonsState(tableButtons || [], elementsUserSettingsState),
    editorButtons: new EditorButtonsState(editorButtons || []),
    summaryData: summaryDataState,
    tileViewItems: new ItemsState(tileViewItems || []),
    listViewItems: new ItemsState(listViewItems || []),
  };
}

/**
 * @param state
 * @returns {{fullName: string, login: string, userID: string}}
 */
function getUser(state: any) {
  return {
    userID: state.user.UserID,
    login: state.user.Name,
    fullName: ''
  };
}

/**
 * Создает настройки для D5DataSource.
 * rowsPerPage - количество загружаемых строк в таблице. number or 'all' - для загрузки всех форм
 * @returns {{rowsPerPage: RowPerPage}}
 */
function getFormDataSource(rowsPerPage: RowPerPage) {
  return {
    rowsPerPage
  };
}

export interface FilterDocPanel {
  filters: FiltersState;
  filterItems: ItemsState;
  formID: string;
  formKey: string;
}

export interface FormObject {
  nestedData: NestedDataState | undefined;
  editableRowData: EditableRowDataState | null;
  kanbanCardItems: ItemsState;
  formItems: ItemsState;
  formGroups: GroupsState;
  formData: Record<string, any>;
  filters: FiltersState;
  formDataSource: {rowsPerPage: RowPerPage};
  currentForm: CurrentFormState;
  formKey: string;
  formID: string;
  title: string;
  sysForm: SysForm;
  columns: D5ColumnsCollection;
  tableButtons: TableButtonsState;
  editorButtons: EditorButtonsState;
  summaryData: SummaryDataState;
  apiOperation: ApiOperationState;
  user: {userID: number; login: string; fullName: string};
  //only for sub forms
  subFormName?: string;
  nestedFieldName?: string;
  order?: number;
  isModified: boolean;
  rowsToUpdate: RowsToUpdate | undefined;
  focusedItem: IFocusedField | undefined;
  userData?: Record<string, any>;
  elementsUserSettings: ElementsUserSettingsState | null;
  filterDockPanel: FilterDocPanel | undefined;
  languages: ILanguage[];
  schedulerResources: UserScriptSchedulerResources;
  schedulerStartDayHour: number,
  schedulerEndDayHour: number,
  tileViewItems: ItemsState;
  listViewItems: ItemsState;
}

export function getNestedData({
  nestedFieldName,
  parentFormID,
  eventType,
  state
}: {
  nestedFieldName: string;
  parentFormID: string;
  eventType: number;
  state: RootState;
}): NestedDataState | undefined {
  /**
   * eventType !== ON_INIT_FORM - костыль для релиза.
   * Получается что здесь проставляется раньше времения formData у формы.
   * У тем самым сразу после ON_INIT_FORM идет onShow без запроса на данные, так как
   * formData уже есть.
   */
  if (!(nestedFieldName && parentFormID && eventType !== USER_EVENT_TYPE.ON_INIT_FORM)) {
    return;
  }
  let nestedData = makeNestedDataSelector()(state, {parentFormID, nestedFieldName});
  nestedData = new NestedDataState(nestedData || []);
  return nestedData;
}

export function createFormObject({
  state,
  formID,
  formKey: propFormKey,
  sender
}: {
  state: RootState;
  formID: string;
  formKey: string;
  sender: FILTER_SENDERS;
  eventType: USER_EVENT_TYPE;
}): FormObject {
  const {Sys_Forms} = state.requests;
  const sysForm = findSysForm(formID, Sys_Forms);
  const user = getUser(state);
  const languages = languagesSelector(state);

  let {
    formKey,
    currentForm,
    filters,
    columns,
    tableButtons,
    editorButtons,
    editableRowData,
    apiOperation,
    elementsUserSettings,
    kanbanCardItems,
    summaryData,
    schedulerResources,
    schedulerStartDayHour,
    schedulerEndDayHour,
    tileViewItems,
    listViewItems
  } = getFormProps({
    state,
    formID: sysForm.Name,
    sysForm,
  });

  const formDataSource = getFormDataSource(currentForm.rowsPerPage);
  const {items: currItems, groups: currGroups, formData: currFormData, title, isModified} = currentForm;

  let formItems = new ItemsState(currItems || []);
  let formGroups = new GroupsState(currGroups || []);
  let formData = Object.assign({}, currFormData);

  return {
    rowsToUpdate: undefined,
    columns,
    tableButtons,
    editorButtons,
    languages,
    nestedFieldName: undefined,
    nestedData: undefined,
    sysForm,
    user,
    formDataSource,
    currentForm,
    formID,
    formKey,
    kanbanCardItems,
    schedulerResources,
    schedulerStartDayHour,
    schedulerEndDayHour,
    focusedItem: currentForm.focusedItem,
    formItems,
    editableRowData,
    formGroups,
    formData,
    filters,
    title,
    isModified,
    apiOperation,
    elementsUserSettings,
    summaryData,
    filterDockPanel: sender === FILTER_SENDERS.FORM ? createFilterDocPanel({formKey: propFormKey, state}) : undefined,
    tileViewItems,
    listViewItems
  };
}
