import {system} from 'services/objects';
import {gridConstants} from 'components/Layout/utils/variables';
import {FormField, SysFormFieldCollection} from 'utilsOld/systemObjects';
import {
  ButtonCreators,
  ELayoutType,
  IFieldOptions,
  ILabel,
  ILayoutItem
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {SysFormFields} from 'services/interfaces/sysObjects';
import {createFieldDataSource} from 'utilsOld/createFieldDataSource';
import {isDefined} from '../typeUtils';
import {groupBy} from 'utilsOld/utility';
import {formFieldValidationGroupName, getTitleAlignment, getTranslatedTitle} from './utils';
import {createFieldTestId} from '../../../utilsOld/createDataTestId';

const {
  MIN_WIDTH: {FIELD_MIN_WIDTH},
  MIN_HEIGHT: {FIELD_MIN_HEIGHT}
} = gridConstants;

interface IFieldProps {
  events?: Record<string, any>[];
  field: FormField;
  formKey: string;
  onValueChanged: IFieldOptions['onValueChanged'];
  onKeyDown?: IFieldOptions['onKeyDown'];
  onFocusOut?: IFieldOptions['onFocusOut'];
  onInitFunction: (_: any) => any;
  validationCreator: (_: {
    fieldName: string;
    formID: string;
    formKey: string;
  }) => IFieldOptions['onValidation'] | undefined;
  value: undefined;
  validationGroup: string;
  formDefaultTitleWidth?: string;
}

const {
  DX_CONTROLS: {dxCheckBox},
  LAYOUT_TYPE: {FORM_FIELD}
} = system;

type Args = Pick<
  IFieldProps,
  | 'onValueChanged'
  | 'onKeyDown'
  | 'onFocusOut'
  | 'onInitFunction'
  | 'formKey'
  | 'formDefaultTitleWidth'
  | 'validationCreator'
> & {
  fields: SysFormFields[];
  hasScroll?: boolean;
};

export const getFormFields = ({fields, hasScroll = true, ...rest}: Args) => {
  const formFields = new SysFormFieldCollection(fields);

  const adjustItems = (items: ILayoutItem<IFieldOptions>[]) => {
    const withParentFieldID = items.filter(item => isDefined(item.options.parentFieldID));

    //{[parentFieldId: children[]]}
    const mapOfChildrenItems = groupBy(
      withParentFieldID,
      ({options: {parentFieldID}}: ILayoutItem<IFieldOptions>) => parentFieldID
    );

    return items.map(item => {
      const {id, options} = item;
      //если есть поля с указанным parentFieldID, то нужно выставить свойство isParentField
      const isParentField = id in mapOfChildrenItems;

      return {
        ...item,
        options: {
          ...options,
          isParentField,
          // isResizable: options.isResizable && hasScroll,
          hasScroll
        }
      };
    });
  };

  let items = formFields.toArrayOfInstances().map(field => {
    return getFieldProps({
      field,
      value: undefined,
      validationGroup: formFieldValidationGroupName(rest.formKey),
      ...rest
    });
  });

  return adjustItems(items);
};

const isResizable = (field: FormField) => {
  return field.isResizableType() || (field.lineCount === -1 && field.isTextArea());
};

const getFieldProps = (props: IFieldProps): ILayoutItem<IFieldOptions> => {
  const formField = props.field;

  const {
    onValueChanged,
    onFocusOut,
    onKeyDown,
    onInitFunction,
    value,
    validationGroup,
    events,
    formKey,
    formDefaultTitleWidth,
    validationCreator
  } = props;

  const {
    ID,
    groupID,
    order,
    isShowTitle,
    titlePosition,
    titleOrientation,
    titleFontStyle,
    titleFont,
    titleColor,
    titleAlignment,
    titleFontSize,
    title,
    name,
    isVisible,
    isReadOnly,
    required,
    displayCustomParam,
    displayType,
    formForSelectByDefault,
    cols,
    offset,
    newLine,
    titleWidth,
    hint,
    docUrl,
    width,
    fieldType,
    borderStyle,
    isLabelMode,
    helpArticleGuid,
    isArray
  } = formField;

  /*
  Ширина кепшена для поля.
  Если у поля проставлена своя ширина по умолчанию, она является самой приоритетной и проставляется в первую очередь
  Если ширина по умолчанию не проставлена и поле находится не в группе берем ширину которая выставлена для всей формы
   */
  const getTitleWidth = () => {
    if (titleWidth) {
      return titleWidth;
    }
    if (!groupID) {
      return formDefaultTitleWidth;
    }
  };

  const label: ILabel = {
    text: getTranslatedTitle(formField.translations) || title,
    // при вибраному titlePosition: on border | floating не показуєм title
    visible: !isLabelMode && isShowTitle,
    location: titlePosition,
    titleWidth: getTitleWidth()
  };

  const dxType = formField.getFieldType();
  const withUSEvent = !formField.isDiagram();

  let options: IFieldOptions = {
    displayCustomParam,
    displayType,
    displayValue: undefined,
    dxType,
    d5Type: formField.d5EditorType(),
    events,
    field: formField,
    fieldType,
    filter: {},
    operationsParams: {},
    formForSelectByDefault,
    formID: formField.getFormID(),
    formKey,
    isCopy: false,
    isDynamic: formField.isDynamicField,
    isDisabled: formField.isDisabled,
    isMultiSelect: formField.isMultiSelect,
    isAdaptiveHeight: !!formField.isAdaptiveHeight,
    isAnyOf: formField.isAnyOf,
    textAlignment: formField.textAlignment,
    textFont: formField.textFont,
    textFontSize: formField.textFontSize,
    isFile: formField.isFile,
    isParentField: false,
    isResizable: isResizable(formField),
    label,
    lineCount: formField.lineCount,
    objectName: formField.objectFieldIDName,
    onFocusOut,
    onKeyDown,
    onValueChanged: props => onValueChanged && onValueChanged({withUSEvent, ...props}),
    onValidation: validationCreator({fieldName: formField.name, formKey, formID: formField.getFormID()}),
    parentFieldID: formField.parentFieldID,
    lookupTagColorSchemeFieldName: formField.LookupTagColorSchemeFieldName,
    lookupTagStylingModeFieldName: formField.LookupTagStylingModeFieldName,
    sortValues: formField.sortValues,
    titlePosition,
    titleOrientation,
    titleFontStyle,
    titleFont,
    titleColor,
    titleAlignment: getTitleAlignment(titleAlignment),
    titleFontSize,
    validationGroup,
    value,
    width,
    borderStyle,
    dataTestId: createFieldTestId(formField.getFormID(), name),
    lookupWithoutArray: !isArray && formField.isLookup()
  };

  if (formField.isLookup() || formField.isEnum() || formField.isListSelector() || formField.isDualListSelector()) {
    const {dataSource, displayExpr, valueExpr, dictObj} = createFieldDataSource(formField, onInitFunction, formKey);
    options = {
      ...options,
      //@ts-ignore
      dataSource,
      displayExpr,
      valueExpr,
      dictObj,
      //@ts-ignore
      createDataSource: () => {
        const {dataSource} = createFieldDataSource(formField, onInitFunction, formKey);
        return dataSource;
      }
    };
  }

  return {
    creator: ButtonCreators.FORM,
    displayType: formField.displayType,
    id: ID,
    isReadOnly,
    isReadOnlyForm: false,
    isRequired: required && dxType !== dxCheckBox,
    isVisible,
    itemType: ELayoutType.FORM_FIELD,
    className: 'd-layout-item -field',
    idAttr: `d5-layout-${FORM_FIELD}-${ID}`,
    name,
    cols,
    offset,
    newLine,
    minWidth: FIELD_MIN_WIDTH,
    minHeight: FIELD_MIN_HEIGHT,
    options,
    order,
    parentID: groupID,
    help: hint,
    helpArticleGuid,
    docUrl
  };
};
