import {isArray, isDefined, isObject} from 'services/SecondaryMethods/typeUtils';
import {loadObjectData} from 'utilsOld/common_requests';
import {ToolBarItem} from 'utilsOld/toolbar_buttons';
import {fields, system} from 'services/objects';
import {FilterField} from 'utilsOld/systemObjects';
import {each, pluck} from 'utilsOld/utility';
import {
  BorderStyleStr,
  ButtonColorScheme,
  ButtonStylingMode
} from '../../services/SecondaryMethods/formItems/itemInterfaces';
import FormTypeUtil from 'utilsOld/systemObjects/SystemFormType';
import {isMobile} from '../../utilsOld/device';

export const createPropertyValuePair = (initialValue: any, focusedObject: any, property: string, value: any) => {
  if (isArray(focusedObject)) {
    for (let key of focusedObject) {
      createPropertyValuePair(initialValue, key, property, value);
    }
  }
  if (isObject(focusedObject)) {
    for (let key in focusedObject) {
      createPropertyValuePair(initialValue, focusedObject[key], property, value);
    }
  }
  if (focusedObject && focusedObject.hasOwnProperty(property) && focusedObject.hasOwnProperty(value)) {
    initialValue[focusedObject[property]] = focusedObject[value];
  }
  return initialValue;
};

export const loadDictionaryValues = async (formField: FilterField, value: any, params?: Record<string, any>) => {
  const data = await loadObjectData({
    columns: [formField.getKeyObjFieldName(), formField.displayField],
    filter: {
      [formField.getKeyObjFieldName()]: value
    },
    objectName: formField.getLinkedName(),
    params
  });
  if (!data?.length) return {};
  let result = data.reduce(
    (res: any, curr: Record<string, any>) => {
      res.value.push(curr[formField.getKeyObjFieldName()]);
      res.displayValue.push(curr[formField.displayField]);
      return res;
    },
    {value: [], displayValue: []}
  );

  if (result.displayValue.length === 1) {
    result.value = result.value[0];
    result.displayValue = result.displayValue[0];
  }
  return result;
};

export const prepareFormDataForRules = async ({
  buttonRules,
  selKeys,
  requestColumns,
  objectName,
  keyField,
  selectedRowData,
  operationsParams
}: {
  buttonRules: Record<string, any>[];
  selKeys: number[];
  requestColumns: string[];
  objectName: string;
  keyField: string;
  selectedRowData?: Record<string, any>;
  operationsParams?: Record<string, any>;
}) => {
  let colNames = pluck(buttonRules, fields.SourceFieldID_Name);
  colNames = colNames.length ? colNames : requestColumns;

  let objectData: any[] = await loadObjectData({
    params: operationsParams,
    filter: {
      [keyField]: selKeys
    },
    columns: colNames,
    objectName
  });
  return getFormDataRules({buttonRules, objectData, selectedRowData});
};

export const getFormDataRules = ({
  buttonRules,
  objectData,
  selectedRowData
}: {
  buttonRules: Record<string, any>[];
  objectData: any[];
  selectedRowData?: Record<string, any>;
}) => {
  if (!buttonRules.length) {
    return {};
  }
  if (selectedRowData) {
    objectData = objectData.map(item => {
      Object.keys(item).forEach(key => {
        if (selectedRowData.hasOwnProperty(key)) {
          item[key] = selectedRowData[key];
        }
      });
      return item;
    });
  }
  const out: Record<string, any> = {};
  objectData.forEach((item: any) => {
    each(item, (value: any, key: any) => {
      const foundRule = buttonRules.filter((rule: {[x: string]: string}) => rule[fields.SourceFieldID_Name] === key);
      if (foundRule.length) {
        out[foundRule[0][fields.DestinationFieldID_Name]] = value;
      }
    });
  });
  return out;
};

export function delayRepaint(func: () => void, wait: number) {
  let timeout: NodeJS.Timeout | null;
  return function () {
    const args = arguments;
    const later = function () {
      timeout = null;
      // @ts-ignore
      func.apply(this, args);
    };
    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
  };
}

const COLUMN_GAP = 8;

export const separateVisibleItems = ({
  buttons,
  toolbarWidth,
  toolbarElement,
  isFirstRun
}: {
  buttons: ToolBarItem[];
  toolbarWidth: number;
  toolbarElement: HTMLElement | null;
  isFirstRun: boolean;
}): {
  visible: ToolBarItem[];
  hidden: ToolBarItem[];
} => {
  const autoItems: ToolBarItem[] = [],
    alwaysItems: ToolBarItem[] = [];

  buttons.forEach(button => {
    button.locateInMenu === 'always' ? alwaysItems.push(button) : autoItems.push(button);
  });

  if (isFirstRun) {
    return {
      visible: autoItems,
      hidden: alwaysItems
    };
  }

  let lastVisibleIndex = 0;
  // Починаємо розрахунок з 38, бо це сума ширини групуючої кнопки "Інше"
  // Дефолтний Розрахнок відбувається без урахування її ширини
  let sum = isMobile() ? 38 : 0;

  const buttonEls = toolbarElement?.querySelectorAll('.toolbar-item') || [];

  for (let i = 0; i < buttonEls.length; i++) {
    const el = buttonEls[i];
    sum += el.clientWidth + COLUMN_GAP;

    if (el.classList.contains('section-item') && !el.classList.contains('section-item-last') ) continue;

    lastVisibleIndex++;
    if (sum > toolbarWidth) {
      lastVisibleIndex--;
      break;
    }
  }

  return {
    visible: autoItems.slice(0, lastVisibleIndex),
    hidden: [...autoItems.slice(lastVisibleIndex), ...alwaysItems]
  };
};

export const buttonColorSchemeToDX = (
  color: ButtonColorScheme | null | undefined
): 'danger' | 'default' | 'normal' | 'success' | 'primary' | 'warning' | 'main-scheme' => {
  switch (color) {
    case ButtonColorScheme.Danger:
      return 'danger';
    case ButtonColorScheme.Success:
      return 'success';
    case ButtonColorScheme.Primary:
      return 'primary';
    case ButtonColorScheme.Default:
      return 'default';
    case ButtonColorScheme.Warning:
      return 'warning';
    case ButtonColorScheme.MainScheme:
      return 'main-scheme';
    default:
      return 'normal';
  }
};

export const buttonStylingModeToDX = (
  stylingMode: ButtonStylingMode | null | undefined
): 'text' | 'outlined' | 'contained' => {
  switch (stylingMode) {
    case ButtonStylingMode.Outlined:
      return 'outlined';
    case ButtonStylingMode.Contained:
      return 'contained';
    default:
      return 'text';
  }
};

export const borderStyleToButtonStylingMode = (borderStyle: BorderStyleStr) => {
  switch (borderStyle) {
    case BorderStyleStr.Default:
      return ButtonStylingMode.Outlined;
    case BorderStyleStr.None:
      return ButtonStylingMode.None;
    default:
      return ButtonStylingMode.Contained;
  }
};

export const getInitFormData = (mode: string, parentFormType: number | undefined, initialFormData: any) => {
  let resultFormData = {};

  const {StartDate, EndDate} = initialFormData || {};

  if (FormTypeUtil.isScheduler(parentFormType) && isDefined(StartDate) && isDefined(EndDate)) {
    resultFormData = initialFormData;
  }

  return mode === 'add' ? resultFormData : initialFormData;
};

export const isOnBorderOrFloatingTitlePos = (titlePosition: string) => {
  return titlePosition === system.TITLE_LOCATION.ONBORDER || titlePosition === system.TITLE_LOCATION.FLOATING;
};
