import {Columns, GridColumn} from 'components/TableCoreOld/column-interfaces';
import {groupBy} from 'utilsOld/utility';
import {isSysField} from 'utilsOld/sysFormUtils';
import {isSystemColumn} from './utils';

export const isSystem = ({dataField, type}: GridColumn) => isSysField(dataField) || isSystemColumn(type!);

export function mergeColumnOptions(
  storedCols: GridColumn[],
  newFlattenColumns: GridColumn[],
  propsArr: Array<keyof GridColumn>
) {
  const getter = (col: GridColumn) => '' + col.dataField + col.name;
  const grouped: Record<string, GridColumn[]> = groupBy(newFlattenColumns, getter);

  const mergeChanges = (aColumns: Columns) => {
    let changed = false;
    let columns = [...aColumns];

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].columns?.length) {
        const cols = mergeChanges(columns[i].columns!);
        if (cols !== columns[i].columns) {
          columns[i] = {...columns[i], columns: cols};
          changed = true;
        }
      }

      let [newColumn] = grouped[getter(columns[i])] || [];
      if ((newColumn && !isSystem(newColumn)) || newColumn?.userData?.isDynamicColumn) {
        for (const prop of propsArr) {
          // @ts-ignore
          if (newColumn[prop] != columns[i][prop]) {
            // @ts-ignore
            columns[i] = {...columns[i], [prop]: newColumn[prop]};
            changed = true;
          }
        }
      }
    }

    return changed ? columns : aColumns;
  };

  return mergeChanges(storedCols);
}
