import {gridConstants} from 'components/Layout/utils/variables';
import {system} from "../../objects";
import FilterField from 'utilsOld/systemObjects/FilterField';
import {getDefaultOperation} from 'services/SecondaryMethods/filterUtils';
import {
  ButtonCreators,
  ELayoutType,
  IFilterOptions,
  ILabel,
  ILayoutItem
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {createFieldDataSource} from 'utilsOld/createFieldDataSource';
import {FormField} from 'utilsOld/systemObjects';
import {
  createFieldID,
  filterFieldValidationGroupName,
  getCustomConditionList,
  getIsCustomConditionList,
  getTitleAlignment,
  getTranslatedTitle
} from './utils';
import {OnFilterValueChanged} from '../../currentForms/types';
import {FILTER_LAYOUT, FILTER_OPERATIONS, TITLE_ALIGNMENT} from '../../interfaces/global-interfaces';
import {
  createDocFilterTestId,
  createHeaderFilterTestId,
  createLayoutFilterTestId
} from '../../../utilsOld/createDataTestId';
import {getFilterOperationNameById, getFilterOperationSignByName} from '../../../utilsOld/helpers';

const {
  MIN_WIDTH: {FILTER_MIN_WIDTH},
  MIN_HEIGHT: {FILTER_MIN_HEIGHT}
} = gridConstants;

//интерфейс для функции которая формирует исходящие параметры фильтра
/**
 * @link getFilterProps
 */
interface IFilterProps {
  filterField: FilterField | FormField;
  lsKey: string;
  onValueChanged: OnFilterValueChanged;
  onInitFunction: (_: any) => any;
  value?: Array<string | number>;
  hasIsBlank: boolean;
  validationGroup?: string;
  showInMain: boolean;
  formKey: string;
  layout: FILTER_LAYOUT;
  conditionListInDependOnForm?: (filterField: Record<string, any>) => Record<string, any> | null;
  getRequired?: (filterField: Record<string, any>, required: boolean) => boolean;
}

//интерфейс для функции которая принимает на вход SysFormFilters
//и обрабатывает их

interface IFilterItems {
  formFilters: Record<string, any>[];
  showInMain: boolean;
  onValueChanged: OnFilterValueChanged;
  onInitFunction: (_: any) => any;
  formFields: Record<string, any>[];
  formKey: string;
  layout: FILTER_LAYOUT;
  conditionListInDependOnForm?: (filterField: Record<string, any>) => Record<string, any> | null;
  getRequired?: (filterField: Record<string, any>, required: boolean) => boolean;
}

export function getFilterItems({
  formFilters,
  showInMain,
  onValueChanged,
  onInitFunction,
  formKey,
  layout,
  conditionListInDependOnForm,
  getRequired
}: IFilterItems) {
  return formFilters.map(field => {
    const filterField = new FilterField(field);

    return getFilterProps({
      filterField,
      lsKey: '',
      onValueChanged,
      onInitFunction,
      hasIsBlank: false,
      value: undefined,
      showInMain,
      formKey,
      layout,
      conditionListInDependOnForm,
      getRequired
    });
  });
}

function getTestId(props: IFilterProps, layout: FILTER_LAYOUT) {
  let dataTestId = createLayoutFilterTestId(props.filterField.formID, props.filterField.name);

  if (layout === FILTER_LAYOUT.DOCK_PANEL)
    dataTestId = createDocFilterTestId(props.filterField.formID, props.filterField.name);

  if (layout === FILTER_LAYOUT.HEADER_FILTER)
    dataTestId = createHeaderFilterTestId(props.filterField.formID, props.filterField.name);
  return dataTestId;
}

export function getFilterProps(props: IFilterProps): ILayoutItem<IFilterOptions> {
  const {
    lsKey = '',
    onValueChanged,
    onInitFunction,
    value,
    hasIsBlank,
    showInMain,
    formKey,
    layout,
    conditionListInDependOnForm = () => null,
    getRequired = (_, required) => required
  } = props;

  const {
    ID,
    title,
    isShowTitle,
    titlePosition,
    titleFontStyle,
    titleFont,
    titleColor,
    titleAlignment,
    titleFontSize,
    formIDObjectIDName,
    required,
    groupID,
    order,
    isShowInMainForm,
    isReadOnly,
    isVisible,
    name,
    cols,
    offset,
    newLine,
    titleWidth,
    hint,
    docUrl,
    width,
    translations,
    borderStyle,
    isLabelMode,
    helpArticleGuid
  } = props.filterField;

   const isDockPanelLayout = layout === FILTER_LAYOUT.DOCK_PANEL;
   const filterTitlePositionByLayout = isDockPanelLayout ? system.TITLE_LOCATION.TOP : titlePosition;
   const filterTitleAlignmentByLayout = isDockPanelLayout? TITLE_ALIGNMENT.LEFT : titleAlignment;

  const label: ILabel = {
    text: getTranslatedTitle(translations) || title,
    // при вибраному titlePosition: on border | floating не показуєм title
    visible: !isLabelMode && isShowTitle,
    location: filterTitlePositionByLayout,
    titleWidth
  };

  const isCustomConditionList = getIsCustomConditionList({
    filterField: props.filterField as FilterField,
    customConditionListInDependOnForm: conditionListInDependOnForm(props.filterField)
  })

  const conditionList = getCustomConditionList({
    filterField: props.filterField as FilterField,
    customConditionListInDependOnForm: conditionListInDependOnForm(props.filterField),
    isLookupOrEnum: props.filterField.isEnum() || props.filterField.isLookup(),
    title: props.filterField.title,
    isMultiSelect: props.filterField.isMultiSelect
  });

  let options: IFilterOptions = {
    dxType: props.filterField.getFieldType(),
    d5Type: props.filterField.d5EditorType(),
    formID:props.filterField.formID,
    textAlignment: props.filterField.textAlignment,
    titleFont,
    titleColor,
    titleAlignment: getTitleAlignment(filterTitleAlignmentByLayout),
    titleFontSize,
    dataTestId: getTestId(props, layout),
    allowNullState: true,
    filterField: props.filterField as FilterField,
    lsKey,
    formKey,
    isShowInMainForm,
    fieldType: props.filterField.fieldType,
    titlePosition: filterTitlePositionByLayout,
    objectName: formIDObjectIDName,
    objectFieldID: props.filterField.objectFieldIDName,
    onValueChanged,
    realOperation: getDefaultOperation(props.filterField.d5EditorType()),
    defaultOperation: isCustomConditionList
      ? // @ts-ignore
      getFilterOperationSignByName(FILTER_OPERATIONS[getFilterOperationNameById(conditionList?.[0])] || '')
      : getDefaultOperation(props.filterField.d5EditorType()),
    defaultValue: null,
    hasIsBlank,
    validationGroup: filterFieldValidationGroupName(formKey),
    value,
    filter: {},
    operationsParams: {},
    label,
    layout,
    lookupTagColorSchemeFieldName: props.filterField.LookupTagColorSchemeFieldName,
    lookupTagStylingModeFieldName: props.filterField.LookupTagStylingModeFieldName,
    width: showInMain ? width : null,
    displayType: props.filterField.displayType,
    sortValues: props.filterField.sortValues,
    borderStyle: borderStyle,
    titleFontStyle,
    isDisabled: props.filterField.isDisabled,
    isAnyOf: props.filterField.isAnyOf,
    isMultiSelect: props.filterField.isMultiSelect,
    conditionList,
    isCustomConditionList,
  };

  if (props.filterField.isLookup() || props.filterField.isEnum() || props.filterField.isListSelector()) {
    const {dataSource, displayExpr, valueExpr} = createFieldDataSource(props.filterField, onInitFunction, formKey);

    options = {
      ...options,
      //@ts-ignore
      dataSource,
      displayExpr,
      valueExpr
    };
  }

  return {
    creator: ButtonCreators.FORM,
    id: ID,
    itemType: ELayoutType.FILTER,
    //для модальной формы нет групп
    parentID: showInMain ? groupID : null,
    isRequired: getRequired(props.filterField, required),
    className: 'd-layout-item -filter form-filter',
    idAttr: createFieldID({
      layout,
      name,
      formKey,
      type: props.filterField.d5EditorType()
    }),
    name,
    cols,
    offset,
    newLine,
    minHeight: FILTER_MIN_HEIGHT,
    minWidth: FILTER_MIN_WIDTH,
    order,
    isVisible,
    isReadOnly,
    options,
    formKey,
    help: hint,
    docUrl,
    helpArticleGuid,
    displayType: props.filterField.displayType,
  };
}
