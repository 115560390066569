import {userFunction} from '../userFunction';
import {D5FormButton} from 'middlewares/userScript/elems';
import {commonResult} from 'middlewares/userScript/results';
import {BaseUserEventArgs} from './types';
import {cardItemsByComponentInitiator} from '../elems/formWithCards/cardItemsByComponentInitiator';

interface Args extends BaseUserEventArgs {
  name: string;
}

export const onCardButtonClick = ({items, groups, eventType, subForms, processedForm, dispatch, parentForm, name, ...rest}: Args) => {
  const cardItems = cardItemsByComponentInitiator(rest.componentInitiator!, processedForm);
  const item = cardItems.getButtons().find(item => item.name === name);

  const currButton =
    processedForm.editorButtons.getD5Button(name) ||
    new D5FormButton({name, formItems: item ? cardItems : items});

  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    name,
    processedForm,
    args: [currButton],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
